// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  Modal,

} from "@material-ui/core";

import { styled, ThemeProvider } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { location, Dog1 } from "../../landingpage/src/assets";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Loader from "../../../components/src/Loader.web";

import ReservationHistoryController, {
  Props,
  configJSON,
  Item,
} from "./ReservationHistoryController";

import PetOwnerNavigationMenu from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import Footer from "../../../components/src/Footer.web";
import { theme } from "../../../components/src/theme";
import { cancel } from "../../shoppingcart/src/assets";
import CustomRating from "../../../components/src/CustomizedComponents.web";
import { Reservations, ReservationsData } from "./types";

export default class ReservationHistory extends ReservationHistoryController {
  constructor(props: Props) {
    super(props);
  }


  cusombutton = () => {
    let buttonText;

    if (this.state.buttonstatus === "accepted" || this.state.buttonstatus === "pending") {
      buttonText = "Edit";
    } else if (this.state.buttonstatus === "draft") {
      buttonText = "Proceed";
    } else {
      buttonText = "View";
    }

    return buttonText;
  }


  tableCells = () => {
    return (
      <>
        <TableCell style={webStyle.tableheadcell}>Property & Address</TableCell>
        <TableCell align="center" style={webStyle.tableheadcell}>Check in</TableCell>
        <TableCell align="center" style={webStyle.tableheadcell}>Check out</TableCell>
        <TableCell align="center" style={webStyle.tableheadcell}>Pets</TableCell>
        {(this.state.buttonstatus === "accepted" || this.state.buttonstatus === "pending") ?
          <TableCell align="center" style={webStyle.tableheadcell}>No. of Days</TableCell>
          : null}
        <TableCell align="center" style={webStyle.tableheadcell}>Total Amount</TableCell>
        {this.state.buttonstatus != "ongoing" && <TableCell align="center" style={webStyle.tableheadcell}>Action</TableCell>}

        <TableCell align="center" style={webStyle.tableheadcell}>Message</TableCell>
      </>
    )
  }

  customTable = () => {

    const renderMessageCell = (chat_id: number) => {
      return (

        <TableCell align="center" style={{ ...webStyle.tabledatastyle, color: "#326478", cursor: "pointer" }}>
          <MailOutlineIcon data-test-id='chat_icon'
            onClick={() => this.seePoChat(chat_id)}
          />
        </TableCell>
      )
    };

    return (
      <CustomTable>
        <TableHead>
          <TableRow>
            {this.tableCells()}

          </TableRow>
        </TableHead>

        <TableBody>

          {this.state.itemsList.length > 0 && this.state.itemsList.map((row: Reservations, index) => {
            const numericPrice = parseFloat(row.data.attributes.price.replace("£", ""));
            const numericPrepaymentPrice = parseFloat(row.data.attributes.pre_payment_amount.replace("£", ""));
            const displayPrice = numericPrepaymentPrice > numericPrice ? row.data?.attributes?.pre_payment_amount : row.data?.attributes?.price;
            const totalPrice = row.data.attributes?.free_cancellation_period ? row.data.attributes.price : displayPrice;
            return (
              <TableRow >
                <TableCell scope="column" style={{ ...webStyle.tabledatastyle, display: "flex" }}>
                  <img src={row?.data?.attributes?.images[0]?.url} style={{ height: "52px", width: "85px", marginRight: "15px" }} alt="" />
                  <Box style={{ display: "flex", flexDirection: "column", height: "52px", marginLeft: "5px" }}>
                    {row.data.attributes.hotel_information.name}<br />
                    <Box style={{ display: "flex", alignItems: "end", fontSize: "14px", fontWeight: 400 }}>
                      <LocationOnOutlinedIcon style={{ color: "#4386A1" }} />
                      <Typography>{row.data.attributes.hotel_information.hotel_city},{row.data.attributes.hotel_information.hotel_country_region}</Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="center" style={webStyle.tabledatastyle}>{this.dateToString(row.data.attributes.check_in_date)}</TableCell>
                <TableCell align="center" style={webStyle.tabledatastyle}>{this.dateToString(row.data.attributes.check_out_date)}</TableCell>

                <TableCell align="center" style={webStyle.tabledatastyle}>
                  {row.data.attributes.number_of_dogs > 0 && `${row.data.attributes.number_of_dogs} Dogs`}
                  {row.data.attributes.number_of_dogs > 0 && row.data.attributes.number_of_cats > 0 && <br />}
                  {row.data.attributes.number_of_cats > 0 && `${row.data.attributes.number_of_cats} Cats`}
                </TableCell>


                {(this.state.buttonstatus === "accepted" || this.state.buttonstatus === "pending") ? <TableCell align="center" style={webStyle.tabledatastyle}>
                  {this.state.totalDays[index]} days
                </TableCell> : null}

                <TableCell align="center" style={webStyle.tabledatastyle}>
                  {this.getPrice(totalPrice, row.data.attributes.total_charges)}
                </TableCell>
                {this.state.buttonstatus != "ongoing" && <TableCell align="center" style={webStyle.editbutton} data-test-id="rowaction" onClick={this.getRowActionHandler(row)}>
                  {this.cusombutton()}
                </TableCell>}
                {renderMessageCell(row.data.attributes.chat_id[0])}
              </TableRow>
            )
          })}
          {this.state.itemsList.length === 0 &&
            <TableRow>
              <TableCell colSpan={8} align="center" style={{ height: 300, borderBottom: 'none' }}>
                <Typography variant="h1" style={webStyle.hotelName}
                  data-test-id='no_reservation'
                >{configJSON.noReservation}</Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </CustomTable>
    );
  };

  getRowActionHandler = (row: Reservations) => {

    return (this.state.buttonstatus === "past" || this.state.buttonstatus === "cancelled" || this.state.buttonstatus === "no_show") ?
      () => this.openViewModal(row.data.id) :
      () => this.onReservation(row.data.id)
  };


  renderModal = () => {
    const coverPhotoMain = this.state.filterdata[0]?.data?.attributes?.images.find((image: { is_cover_image: any; }) => image.is_cover_image);

    return (
      <>
        <Modal
          open={this.state.viewModal}
          onClose={this.closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-test-id="viewmodal"
        >


          <Box
            style={{
              position: "absolute",
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 770,
              height: 378,
              backgroundColor: "#fff",
              boxShadow: "24px",
              padding: "0px 20px",
              borderRadius: 15
            }}
          >
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", height: 55 }}>
              <Button
                style={{ marginTop: 20, marginRight: -5 }}
                data-test-id="cancelview"
                onClick={() => this.closeModal()}
              >
                <img src={cancel} alt="" />
              </Button>
            </Box>
            <Box
              style={{
                display: "flex", alignItems: "center",
                justifyContent: "space-evenly", height: "82%", width: "100%", columnGap: "20px"
              }}>

              <Box
                style={{ ...webStyle.viewmodalinnerbox, boxShadow: "rgba(67, 134, 161, 0.15) 0px 4px 8px 0px" } as React.CSSProperties}
              >
                <img src={coverPhotoMain || this.state.filterdata[0]?.data?.attributes?.images[0]?.url} alt="" style={webStyle.modalimg} />

                <Box style={{ width: "84%", marginTop: "15px" }}>
                  <Typography
                    style={webStyle.veiwhotelname}
                  >
                    {this.state.filterdata[0].data.attributes.hotel_information.name}
                  </Typography>
                </Box>

                <Box style={{ width: "87%", marginTop: "5px", display: "flex" }}>
                  <img src={location} width={24} height={24} alt="" />
                  <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>{this.state.filterdata[0].data.attributes.hotel_information.hotel_city},{this.state.filterdata[0].data.attributes.hotel_information.hotel_country_region}</Typography>

                </Box>

                <Box style={{
                  width: "88%", marginTop: "5px", display: "flex", justifyContent: "space-evenly",
                  alignSelf: "flex-start", marginLeft: "-8px"
                }}>
                  <CustomRating rating={this.state.filterdata[0].data.attributes.hotel_information.rating} />
                  <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>{this.state.filterdata[0].data.attributes.hotel_information.rating}</Typography>
                  <Typography variant="body1" color="textSecondary" style={webStyle.locationName}>{this.state.filterdata[0].data.attributes.hotel_reviews_count} Reviews</Typography>
                </Box>

              </Box>

              <Box
                style={webStyle.viewmodalinnerbox as React.CSSProperties}
              >
                <Box style={{ width: "90%", padding: "10px 5px", marginTop: "5px", display: "flex", justifyContent: "space-between", borderBottom: "solid 1px #B2E4F8" }}>
                  <Typography style={{ fontSize: "14px", fontWeight: 700 }}>Check in - out</Typography>
                  <Typography style={{ fontSize: "14px", fontWeight: 700 }}>{this.dateToString(this.state.filterdata[0].data.attributes.check_in_date)}<span style={{ fontWeight: 400 }}> to&nbsp;</span>{this.dateToString(this.state.filterdata[0].data.attributes.check_out_date)}</Typography>

                </Box>


                {this.renderClaculation()}

              </Box>
            </Box>
          </Box>
        </Modal>
      </>
    )
  }

  renderClaculation = () => {
    const { filterdata, totalcalculationdays } = this.state




    const selected_room_dog = filterdata[0]?.data?.attributes?.for_dog?.room_type_for_dog
    const selected_room_cat = filterdata[0]?.data?.attributes?.for_cat?.room_type_for_cat

    const dogcount = (filterdata[0]?.data?.attributes?.number_of_dogs)
    const catcount = (filterdata[0]?.data?.attributes?.number_of_cats)


    const selected_service_dog = filterdata[0]?.data?.attributes?.for_dog?.additional_services_for_dog || []
    const selected_service_cat = filterdata[0]?.data?.attributes?.for_cat?.additional_services_for_cat || []
    const price = parseFloat(filterdata[0]?.data?.attributes?.price.replace("£", ""));
    const pre_payment_price = parseFloat(filterdata[0]?.data?.attributes?.pre_payment_amount.replace("£", ""));
    const displayPrice = pre_payment_price > price ? filterdata[0]?.data?.attributes?.pre_payment_amount : filterdata[0]?.data?.attributes?.price;
    const totalPrice = filterdata[0]?.data?.attributes?.free_cancellation_period ? filterdata[0]?.data?.attributes?.price : displayPrice
    return (
      <>

        {selected_room_dog?.length > 0 && selected_room_dog.map((value: any) => {

          return (
            <>


              <Box style={{ width: "90%", padding: "5px 5px", marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`£${value.price} ${value.room_type} for ${dogcount} dog X ${totalcalculationdays} Days`}</Typography>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`£${value.price * totalcalculationdays}`}</Typography>
              </Box>
            </>
          )
        })}


        {selected_room_cat?.length > 0 && selected_room_cat.map((value: any) => {
          return (
            <>
              <Box style={{ width: "90%", padding: "5px 5px", marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`£${value.price} ${value.room_type} for ${catcount} cat X ${totalcalculationdays} Days`}</Typography>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`£${value.price * totalcalculationdays}`}</Typography>
              </Box>
            </>
          )
        })}






        {(!selected_service_cat?.includes(null) && selected_service_dog?.length > 0) && selected_service_dog?.map((value: any) => {
          return (
            <>
              <Box style={{ width: "90%", padding: "5px 5px", marginTop: "5px", display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`${value.name} for ${dogcount} dog`}</Typography>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`£${value.price * dogcount}`}</Typography>
              </Box>
            </>
          )
        })}

        {(!selected_service_cat?.includes(null) && selected_service_cat?.length > 0) && selected_service_cat?.map((value: any) => {
          return (
            <>


              <Box style={{ width: "90%", padding: "5px 5px", marginTop: "5px", display: "flex", justifyContent: "space-between" }}>

                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`${value.name} for ${catcount} cat`}</Typography>
                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>{`£${value.price * catcount}`}</Typography>
              </Box>
            </>
          )
        })}


        <Box style={{ width: "90%", padding: "10px 5px", marginTop: "20px", borderRadius: "8px", display: "flex", justifyContent: "space-between", backgroundColor: "#B2E4F8" }}>
          <Typography style={{ fontSize: "14px", fontWeight: 700 }}>Total</Typography>
          <Typography style={{ fontSize: "14px", fontWeight: 700 }}>{this.getTotalPrice(totalPrice, filterdata[0].data.attributes?.total_charges)}</Typography>

        </Box>

      </>
    )

  }


  getButtonStyle = (status: string) => {
    return this.state.buttonstatus === status
      ? webStyle.headbuttons2
      : webStyle.headbuttons1;
  };


  render() {


    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ width: "100%", padding: "0px" }}>
          <PetOwnerNavigationMenu navType="fullNavbar"
            navigation={this.props.navigation} id=""
            // profilePhoto={this.state.navbarProfilePhoto} 
            activeLink={6}
          />
          <Box sx={webStyle.mainContainer}>
            <Box style={webStyle.contentbox as React.CSSProperties}>
              <Box style={webStyle.buttonbox}>
                <Button
                  style={this.getButtonStyle("accepted") as React.CSSProperties}
                  onClick={this.AcceptButton}
                  data-test-id="buttonstatus0"
                >
                  Accepted
                </Button>
                <Button
                  style={this.getButtonStyle("pending") as React.CSSProperties}
                  onClick={this.pendingbutton}
                  data-test-id="buttonstatus1"
                >
                  Pending
                </Button>
                <Button
                  style={this.getButtonStyle("ongoing") as React.CSSProperties}
                  onClick={this.ongoingButton}
                  data-test-id="ongoing_button"
                >
                  Ongoing
                </Button>
                <Button
                  style={this.getButtonStyle("past") as React.CSSProperties}
                  onClick={this.pastbutton}
                  data-test-id="buttonstatus2"
                >
                  Past
                </Button>
                <Button
                  style={this.getButtonStyle("cancelled") as React.CSSProperties}
                  onClick={this.canceledbutton}
                  data-test-id="buttonstatus4"
                >
                  Cancelled
                </Button>
                <Button
                  style={this.getButtonStyle("no_show") as React.CSSProperties}
                  onClick={this.noShowButton}
                  data-test-id="no_show_button"
                >
                  No Show
                </Button>

              </Box>

              {this.customTable()}

            </Box>

          </Box>

          {this.state.filterdata.length > 0 && this.renderModal()}


        </Container>
        <Footer color="#CCEDFB" footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
        <Loader loading={this.state.historyLoader} />
      </ThemeProvider>
    );
  }
}

const CustomTable: any = styled(Table)({
  marginBottom: "40px",
  "& tr": {
    boxShadow: "rgba(67, 134, 161, 0.15) 0px 4px 8px 0px",
    height: "68px",
    margin: "10px 0px"
  },
  "& th": {
    borderBottom: "1px solid #65C9F2"
  },
  "& td": {
    borderBottom: "1px solid #65C9F2"
  }
})

const webStyle = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#CCEDFB",
    minHeight: "100vh",
  },
  contentbox: {
    backgroundColor: "#fff",
    width: "80%",
    // height:"300px",
    borderRadius: "20px",
    marginTop: "95px",
    padding: "20px"
  },
  buttonbox: {
    // backgroundColor:"yellow",
    height: "100px",
    display: "flex",
    alignItems: "center"
  },
  headbuttons1: {
    height: "44px",
    borderRadius: "26px",
    padding: "10px 20px",
    marginRight: "15px",
    border: "solid 1px #B2E4F8",
    fontSize: "14px",
    fontWeight: 400,
    textTransform: "none"
  },
  headbuttons2: {
    height: "44px",
    borderRadius: "26px",
    padding: "10px 20px",
    marginRight: "15px",
    backgroundColor: "#4386A1",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "none",
    color: "#fff"
  },
  tableheadcell: {
    color: "#4386A1",
    fontSize: "14px",
    fontWeight: 700
  },
  tabledatastyle: {
    fontSize: "16px",
    fontWeight: 700,

  },
  editbutton: {
    fontSize: "16px",
    fontWeight: 700,
    textDecorationLine: "underline",
    color: "#EA0C78",
    cursor: "pointer"
  },
  viewmodalinnerbox: {
    width: "47%",
    height: "95%",
    // background: "yellow",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  modalimg: {
    width: 300,
    height: 188
  },
  veiwhotelname: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#000"
  },
  hotelName: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1rem"
  },
  locationName: {
    fontWeight: theme.typography.fontWeightRegular,
    minHeight: 24
  },
};

// Customizable Area End

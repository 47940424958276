import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { DateObject } from "react-multi-date-picker";
import { BlockOffDays, BoBlockedOffDay, CreateYourOwnService, HotelAdditionalService, HotelDetails, HotelDetailsForm, HotelList, LocationDetails, LocationList, RepresentativeDetail, RoomManipulate, Root, RootDetails, ServiceAdditional, ServiceItem } from "./types";
import React from "react";
import { checkApiErrorResponce, emailValidation, nameValidation, numberValidation, numeracalCharacterValidation, textValidation } from "../../../components/src/CommonFunctions";
// Customizable Area Start
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

interface CheckInCheckOut {
    checkInStart: string
    checkInEnd: string
    checkOutStart: string
    checkOutEnd: string

}
interface StatusCheckInCheckOut {
    checkInStartStatus: boolean,
    checkInEndStatus: boolean,
    checkOutStartStatus: boolean,
    checkOutEndStatus: boolean,
}

interface DayCareServiceAttributes {
    spaces: number,
    price_for_one_day: string;
    price_for_two_days: string;
    price_for_three_days: string;
    price_for_four_days: string;
    price_for_five_days: string;
    price_for_six_days: string;
    price_for_seveen_days: string;
    weekly_price_for_one_day: string;
    weekly_price_for_two_days: string;
    weekly_price_for_three_days: string;
    weekly_price_for_four_days: string;
    weekly_price_for_five_days: string;
    weekly_price_for_six_days: string;
    weekly_price_for_seveen_days: string;
}

interface DayCareService {
    id: string;
    type: string;
    attributes: DayCareServiceAttributes;
}

interface DayCareServices {
    data: DayCareService[];
}

interface RootObject {
    day_care_services: DayCareServices;
}

export interface Tabs {
    id: number,
    tabName: string,
    tabkey: string
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

export interface Questions {
    conditions: string
    question1: string;
    question2: string
    question3: string
}
interface RoomFields {
    roomName: string,
    roomDescription: string
}
interface Location {
    lat: string; // Assuming lat and lon are coming as strings
    lon: string;
}
interface LinkObject {
    url: string;
    title: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    id: string;
    navigation: any;
    // Customizable Area Start
    hotelName?: string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabsList: Tabs[]
    selectedTab: number,
    hotelDetails: any,
    hotelData: any,
    threeDotsStatus: boolean,
    editPhotosStatus: boolean,
    reviewsList: any,
    openDateStatus: boolean
    dateRange: string,
    percentageDropDownStatus: boolean,
    propertyNameEditStatus: boolean,
    contactDetailsEditStatus: boolean;
    descriptionEditStatus: boolean;
    stayMoreThan30NightStatus: boolean;
    blockOffDaysEditStatus: boolean;
    addressEditStatus: boolean;
    hotelForm: HotelDetailsForm;
    hotelFormErrors: any;
    selectedRange: DateObject[];
    startDate: any;
    endDate: any;
    aleadyUploadFiles: any,
    newelyUploadFiles: any,
    allUploadedFiles: any,
    pageLoading: boolean;
    selectImageId: string | number;
    editRoomStatus: boolean
    additionalServicesDropDown: boolean;
    servicesList: any;
    addNewServices: any[]
    roomDetails: any[],
    chnagedRoomData: any[],
    addRoomType: boolean,
    propertiesCount: number,
    petsCount: number,
    petType: string,
    roomPricesArray: any[],
    RoomFields: RoomFields,
    additionalServicesStatus: boolean;
    additionalServicesList: HotelAdditionalService[],
    ownServices: CreateYourOwnService[],
    deletedAdditionalServices: HotelAdditionalService[],
    deletedOwnServices: CreateYourOwnService[],
    roomEditStatus: boolean
    selectedHotelIdEdit: number,
    newAdditionalServicesStatus: boolean,
    filteredServicesList: any[],
    newAdditionalServices: HotelAdditionalService[];
    newelyCreateOwnServices: CreateYourOwnService[]
    editedAdditionalServices: HotelAdditionalService[],
    editedOwnServices: CreateYourOwnService[],
    validationErrors: {
        roomName: string
        roomDescription: string,
        roomPriceError: string,
        propertiesError: string,
        petsError: string
    },
    showEmptyFieldError: boolean,
    roomErrors: any[];
    newlyAddedServices: HotelAdditionalService[];
    representataiveEditStatus: boolean,
    salutataionDropDownStatus: boolean,
    repCountryCodeStatus: boolean
    countryCodeStatus: boolean,
    moreDescription: boolean,
    additionalInputErrors: { [key: string]: string };
    ownServiceInputErrors: { [key: string]: string };
    blockedCalenderStatus: number,
    fromDate: any,
    toDate: any,
    selectedBlockOffDateRange: DateObject[],
    dateRangeBlock: string,
    boBlockedOffDay: BoBlockedOffDay[],
    representativeDetails: RepresentativeDetail[],
    repErrors: any[],
    repId: number,
    repCountryCode: number,
    editRrepresentativeDetails: RepresentativeDetail[],
    toasterStatus: boolean,
    toasterText: string,
    blockOffDays: BlockOffDays[],
    addressCountryStatus: boolean
    coords: [number, number];
    isMapSet: boolean;
    longitude: number;
    latitude: number;
    socialLinksData: { icons: string, navigationUrl: string }[];
    disableActiveStatus: boolean
    poBOLinksData: LinkObject[]

    dayCarePriceList: RootObject;
    oneTimePrice: string[];
    weeklyPrice: string[];
    errorMsg: string;
    dayCareId: string;
    dayCareEditResp: { day_care_service: { data: object } };
    isSuccess: boolean;
    isEnable: boolean;
    stayMoreThan30Days: string;
    deleteRoomDailogStatus: boolean;
    licienceNumberEditStatus: boolean
    room_id: number
    spaces: string;
    checkInCheckOutStatus: boolean
    checkInCheckOut: CheckInCheckOut;
    status_checked: StatusCheckInCheckOut;
    selectTiming: boolean
    titleEditStatus:boolean;
    locationList:any;
    locationListPopup:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class SinglePropertyController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdgetHotelDetails: string = '';
    apiCallIdgetHotelReviewDetails: string = "";
    apiCallIdUpdateHotel: string = "";
    apiCallIdUpdateDeletePhotos: string = "";
    apiCallIdAdditionalServices: string = ""
    apiCallIdRommDetails: string = ""
    apiCallIdCreateRoom: string = "";
    apiCalIdUpdateRoomDetails: string = ""
    apiCalIdUpdateServicesDetails: string = ""
    apiCallIdgetAdditionalServices: string = ""
    apiCallIdGetRepresentativeDetails: string = ''
    apiCallIdUpdateDeleteReservationDetails: string = ""
    getLatLongCallId: string = "";
    apiCallIdDisableActiveHotels: string = ""
    dayCareServiceCallId: string = "";
    editDayCareServiceCallId: string = "";
    disableCallId: string = "";
    enableCallId: string = "";
    apiCallIdDeleteRoom: string = ""
    apiCallIdMoveforward: string = ""
    apiCallIdGetLocationPostcode:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        const date = new DateObject();
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area Start
            , getName(MessageEnum.NavigationPayLoadMessage),


            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabsList: [
                { id: 1, tabName: "About The Listing", tabkey: "listing" },
                { id: 2, tabName: "Photos", tabkey: "photos" },
                { id: 3, tabName: "Type of Rooms", tabkey: "listing" },
                { id: 4, tabName: "Reviews", tabkey: "listing" },
                { id: 5, tabName: "Payment Information", tabkey: "listing" },
                { id: 6, tabName: "Cancellation Policy", tabkey: "listing" }

            ],
            selectedTab: 1,
            hotelDetails: {},
            threeDotsStatus: false,
            editPhotosStatus: false,
            reviewsList: {},
            openDateStatus: false,
            dateRange: "",
            percentageDropDownStatus: false,
            propertyNameEditStatus: false,
            contactDetailsEditStatus: false,
            descriptionEditStatus: false,
            stayMoreThan30NightStatus: false,
            stayMoreThan30Days: "",
            blockOffDaysEditStatus: false,
            addressEditStatus: false,
            hotelForm: {
                propertyName: "",
                stayMoreThan30Night: "",
                licienceNumber: "",
                phonecountryCode: "",
                phoneNumber: "",
                emailAddress: "",
                description: "",
                houseNumber: "",
                additionalAddress: "",
                town: "",
                county: "",
                postCode: "",
                country_region: "",
                representativeFirstName: "",
                representativeSalutation: "",
                representativeSurname: "",
                representativePhoneNumber: "",
                representativeCountryCode: "",
                longitude: 0,
                latitude: 0,
                title:""
            },
            hotelFormErrors: {},
            selectedRange: [],
            startDate: "",
            endDate: "",
            aleadyUploadFiles: [],
            newelyUploadFiles: [],
            allUploadedFiles: [],
            pageLoading: false,
            selectImageId: 0,
            editRoomStatus: false,
            additionalServicesDropDown: false,
            servicesList: [],
            addNewServices: [],
            roomDetails: [],
            chnagedRoomData: [

            ],
            addRoomType: false,
            propertiesCount: 0,
            petsCount: 0,
            petType: "Dog",
            roomPricesArray: [],
            RoomFields: {
                roomName: "",
                roomDescription: ""
            },
            additionalServicesStatus: false,
            additionalServicesList: [],
            ownServices: [],
            deletedAdditionalServices: [],
            deletedOwnServices: [],
            roomEditStatus: false,
            selectedHotelIdEdit: -1,
            newAdditionalServicesStatus: false,
            filteredServicesList: [],
            newAdditionalServices: [],
            newelyCreateOwnServices: [],
            editedAdditionalServices: [],
            editedOwnServices: [],
            validationErrors: {
                roomName: "",
                roomDescription: "",
                roomPriceError: "",
                propertiesError: "",
                petsError: ""
            },
            showEmptyFieldError: false,
            roomErrors: [],
            newlyAddedServices: [],
            representataiveEditStatus: false,
            salutataionDropDownStatus: false,
            repCountryCodeStatus: false,
            countryCodeStatus: false,
            moreDescription: false,

            additionalInputErrors: {},
            ownServiceInputErrors: {},
            blockedCalenderStatus: 0,
            fromDate: date.format('DD-MM-YYYY'),
            toDate: date.format('DD-MM-YYYY'),
            dateRangeBlock: '',
            boBlockedOffDay: [],
            selectedBlockOffDateRange: [],
            representativeDetails: [],
            repErrors: [],
            repId: -1,
            repCountryCode: -1,
            editRrepresentativeDetails: [],
            toasterStatus: false,
            toasterText: "",
            blockOffDays: [],
            addressCountryStatus: false,
            coords: [51.5074456,-0.1277653],
            isMapSet: false,
            longitude: 0,
            latitude: 0,
            socialLinksData: [],
            hotelData: {},
            disableActiveStatus: false,
            poBOLinksData: [],



            dayCarePriceList: {
                day_care_services: {
                    data: []
                }
            },
            oneTimePrice: [
                "",
                "",
                "",
                "",
                "",
                "",
                ""
            ],
            weeklyPrice: [
                "",
                "",
                "",
                "",
                "",
                "",
                ""
            ],
            errorMsg: "",
            dayCareId: "",
            dayCareEditResp: {
                day_care_service: {
                    data: {}
                }
            },
            isSuccess: false,
            isEnable: true,
            deleteRoomDailogStatus: false,
            room_id: 0,
            licienceNumberEditStatus: false,
            spaces: "",
            checkInCheckOutStatus: false,
            checkInCheckOut: {
                checkInStart: "08:00",
                checkInEnd: "11:30",
                checkOutStart: "18:00",
                checkOutEnd: "19:30",
            },
            status_checked: {
                checkInStartStatus: false,
                checkInEndStatus: false,
                checkOutStartStatus: false,
                checkOutEndStatus: false,
            },
            selectTiming: false,
            titleEditStatus:false,
            locationList:[],
            locationListPopup:false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    // Customizable Area Start
    async componentDidMount() {
        this.manageTabs()
        this.getRepresentativeDetails()
        const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
        this.setState({ socialLinksData: mySocialLinksData });
        const allLinksData = await getStorageData("BOandPOLinks", true)
        this.setState({ poBOLinksData: allLinksData })
    }

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (this.handleNavigationMessage(message)) return;
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        switch (apiRequestCallId) {
            case this.apiCallIdgetHotelDetails:
                if (responseJson && !responseJson.errors) {
                    this.apiResponceHotelDetails(responseJson)
                    let blockOfDays = responseJson.data?.attributes?.bo_blocked_off_days.map((days: BoBlockedOffDay) => {
                        return {
                            id: days.id,
                            fromDate: days.from_date && moment(days.from_date).format("DD/MM/YYYY"),
                            toDate: days.to_date && moment(days.to_date).format('DD/MM/YYYY'),
                            selectedBlockOffDateRange: [new DateObject(days.from_date), new DateObject(days.to_date)],
                            type: 'alreadyExist',
                            hotelId: days.hotel_id,
                        }
                    })
                    let selectedTimiing = {
                        checkInStart: responseJson.data?.attributes?.check_in_start,
                        checkInEnd: responseJson.data?.attributes?.check_in_end,
                        checkOutStart: responseJson.data?.attributes?.check_out_start,
                        checkOutEnd: responseJson.data?.attributes?.check_in_end,

                    }
                    this.setState({
                        pageLoading: false,
                        hotelDetails: responseJson?.data,
                        hotelData: responseJson?.data,
                        additionalServicesList: responseJson.data?.attributes?.hotel_additional_services,
                        ownServices: responseJson.data?.attributes?.['Create your own services'],
                        blockOffDays: blockOfDays,
                        selectTiming: responseJson.data?.attributes?.check_in_check_out_times_present,
                        checkInCheckOut: selectedTimiing
                    })
                } else if (responseJson.errors) {
                    checkApiErrorResponce(responseJson, this.props.navigation)
                }
                break;

            case this.apiCallIdgetHotelReviewDetails:
                this.apiResponceReviewDetails(responseJson)

                break;

            case this.apiCallIdUpdateDeletePhotos:
                this.apiResponceHotelDetail(responseJson)
                break;

            case this.apiCallIdUpdateHotel:
                this.apiResponceHotelDetail(responseJson)
                break;

            case this.apiCallIdRommDetails:
                this.apiResponceRoomDetails(responseJson)
                break;

            case this.apiCallIdCreateRoom:
                this.apiResponceCreateRoomDetails(responseJson)
                break;

            case this.apiCalIdUpdateRoomDetails:
                this.apiResponceUpdateRoomDetails(responseJson)
                break;

            case this.apiCallIdgetAdditionalServices:
                this.apiResponcegetAdditionalServices(responseJson)
                break;
            case this.getLatLongCallId:
                this.latAndLongresponce(responseJson)
                break;
            case this.apiCallIdGetLocationPostcode:
                this.getPostcodeApiresponce(responseJson);
                break;
            case this.apiCalIdUpdateServicesDetails:
                this.apiResponceUpdateserviceDetails(responseJson)
                break;
            case this.apiCallIdGetRepresentativeDetails:
                this.apiResponceRepresentativeDetails(responseJson)
                break;
            case this.apiCallIdDisableActiveHotels:
                this.apiResponceHotelDetail(responseJson)
                break;
            case this.apiCallIdUpdateDeleteReservationDetails:
                if (responseJson && !responseJson.errors) {
                    window.scroll(0, 0)
                    this.setState({ representataiveEditStatus: false, pageLoading: false }, () => {
                        this.getRepresentativeDetails()
                    })
                } else if (responseJson?.errors) {
                    this.setState({
                        pageLoading: false,
                        toasterStatus: true,
                        toasterText: responseJson.errors[0]?.representative_details || ""
                    })
                }

                break;
            case this.dayCareServiceCallId:
                this.dayCareApiCall(responseJson);
                break;
            case this.editDayCareServiceCallId:
                this.editDayCareServiceResponnse(responseJson);
                break;
            case this.enableCallId:
                this.enabledRoomSuccessCallBack();
                break;
            case this.disableCallId:
                this.disabledRoomSuccessCallBack();
                break;
            case this.apiCallIdDeleteRoom:
                this.apiResponceDeleteRoom(responseJson)
                break;
            case this.apiCallIdMoveforward:
                this.apiResponceHotelDetail(responseJson)
                break;
            default:
                break;
        }

        // Customizable Area End
    }

    // Customizable Area Start
    apiResponceDeleteRoom = (responseJson: RootDetails) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ pageLoading: false }, () => {
                this.closeDeleteModalDailog()
                this.getHotelRooms()
            })

        } else if (responseJson.errors) {
            this.setState({
                pageLoading: false,
                toasterStatus: true,
                toasterText: responseJson.errors[0].message,
            }, () => {
                this.closeDeleteModalDailog()

            })
        }
    }

    apiResponceReviewDetails = (responseJson: Root) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                reviewsList: responseJson,
                openDateStatus: false, pageLoading: false
            })

        } else if (responseJson.errors) {
            checkApiErrorResponce(responseJson, this.props.navigation)
        }
    }
    apiResponceRepresentativeDetails = (responseJson: Root) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ representativeDetails: responseJson.representative_details, pageLoading: false })
        }
    }
    latAndLongresponce = (responseJson:LocationDetails) => {
        if (responseJson.length > 0) {
            this.setState({
              locationList: responseJson||[],
              isMapSet: true,
              locationListPopup: true});
          }else if(responseJson.length ===0){
            this.setState({
                locationList:[]});
            this.closeLocationPopup()
          }
    }
    getPostcodeApiresponce=(responseJson:LocationList) => {
        if (responseJson) {
            this.setState({hotelForm:{...this.state.hotelForm,houseNumber:`${responseJson.address.house_number || ''} ${responseJson.address.road || ''}`.trim(),postCode:responseJson.address.postcode||""}})
          }
    }
    apiResponceHotelDetail = (responseJson: HotelList) => {
        if (responseJson && !responseJson.errors) {
            this.getHotel()

        } else if (responseJson.errors) {
            checkApiErrorResponce(responseJson, this.props.navigation)
        }
    }

    handleNavigationMessage = (message: Message) => {
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            const sessionData = message.getData(getName(MessageEnum.HotelDetails));
            if (sessionData && sessionData.filterId === 5) {
                this.setState({ selectedTab: sessionData.filterId });
            }
            return true;
        }
        return false;
    }

    apiResponceRoomDetails = (responseJson: any) => {
        if (responseJson && responseJson.length === 0) {
            this.setState({ pageLoading: false, chnagedRoomData: [] })

        }
        if (responseJson.length > 0 && !responseJson.errors) {

            this.setState({ roomDetails: responseJson, pageLoading: false }, () => {
                let manipulateRoomsData = this.state.roomDetails.map((room: RootDetails) => {
                    return {
                        description: room.description,
                        room_for: room.room_for,
                        price: room.price,
                        max_capacity: room.max_capacity,
                        room_type: room.room_type,
                        pet_count: room.pet_count,
                        add_pet: room.add_pet,
                        "min_stay": room.min_stay,
                        "max_stay": room.max_stay,
                        num_rooms: Number(room.num_rooms),
                        num_dogs: room.num_dogs,
                        num_cats: room.num_cats,
                        "room_id": room.id,
                        "hotel_id": room.hotel_id,
                        pet_room_prices: room.pet_room_prices,
                        status: room.status

                    }

                })
                this.setState({ chnagedRoomData: manipulateRoomsData })

            })
        } else if (responseJson.errors) {
            checkApiErrorResponce(responseJson, this.props.navigation)
        } else {
            if (this.state.hotelDetails?.attributes?.pet_type === "daycare_services") {
                this.getDayCareServiceData();
            }
        }
    }
    apiResponceCreateRoomDetails = (responseJson: RootDetails) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ addRoomType: false, pageLoading: false }, () => {
                this.getHotel()
                this.getHotelRooms()
            })

        } else if (responseJson.errors) {
            checkApiErrorResponce(responseJson, this.props.navigation)
        }
        this.setState({
            petsCount: 0,
            roomPricesArray: [],
            propertiesCount: 0,
            petType: "Dog",
            RoomFields: {
                roomName: "",
                roomDescription: ""
            }
        })
    }
    apiResponceUpdateRoomDetails = (responseJson: { errors: string; updated: { data: { id: number; }; }; }) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                selectedHotelIdEdit: -1,
                pageLoading: false
            }, () => {
                this.getHotel()
                this.getHotelRooms()
                //For resolving blank page when user try to update blocked days in a case where hotel have a disable room
                // this.updateDisable(responseJson.updated.data.id)
            })

        } else if (responseJson.errors) {
            checkApiErrorResponce(responseJson, this.props.navigation)
        }
    }

    apiResponceUpdateserviceDetails = (responseJson: RootDetails) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                deletedAdditionalServices: [], deletedOwnServices: [],
                additionalServicesStatus: false,
                newlyAddedServices: [],
                newelyCreateOwnServices: [],
                editedAdditionalServices: [],
                editedOwnServices: [],
                pageLoading: false
            }, () => {
                this.getHotel()
                this.getHotelRooms()
            })

        } else if (responseJson.errors) {
            checkApiErrorResponce(responseJson, this.props.navigation)
        }
    }

    apiResponcegetAdditionalServices = (responseJson: ServiceAdditional) => {
        if (responseJson && !responseJson.errors) {
            let filteredList: { attributes: { service_name: string; }; }[] = []
            responseJson.data.forEach((item: { attributes: { service_name: string; }; }) => {
                let found = false;
                this.state.additionalServicesList.forEach(service => {
                    if (item.attributes.service_name === service.name) {
                        found = true;
                    }
                });
                if (!found) {
                    filteredList.push(item);
                }
            });
            this.setState({ filteredServicesList: filteredList });
        } else if (responseJson.errors) {
            checkApiErrorResponce(responseJson, this.props.navigation)
        }
    }
    manageTabs = () => {
        if (this.state.selectedTab === 1 || this.state.selectedTab === 2) {
            this.getHotel();
            this.getRepresentativeDetails()

        } else if (this.state.selectedTab === 3) {
            this.getHotelRooms()
        }

    }
    apiResponceHotelDetails = (responceJson: HotelDetails) => {
        let hotelForm = {
            propertyName: responceJson.data.attributes.name,
            stayMoreThan30Night: responceJson.data.attributes.more_than_30_days ? 'true' : 'false',
            licienceNumber: responceJson.data.attributes.license_number,
            phonecountryCode: responceJson.data.attributes.phone_number_country_code,
            phoneNumber: responceJson.data.attributes.phone_number,
            emailAddress: responceJson.data.attributes.email,
            description: responceJson.data.attributes.description,
            houseNumber: responceJson.data.attributes.hotel_address_line_1,
            additionalAddress: responceJson.data.attributes.hotel_address_line_2,
            town: responceJson.data.attributes.hotel_city,
            county: responceJson.data.attributes.hotel_country,
            postCode: responceJson.data.attributes.hotel_postcode,
            country_region: responceJson.data.attributes.hotel_country_region,
            representativeFirstName: responceJson.data.attributes.first_name,
            representativeSalutation: responceJson.data.attributes.representative_contact_title,
            representativeSurname: responceJson.data.attributes.surname,
            representativePhoneNumber: responceJson.data.attributes.reprsentive_phone_number,
            representativeCountryCode: responceJson.data.attributes.representive_country_code,
            latitude: responceJson.data.attributes.latitude,
            longitude: responceJson.data.attributes.longitude,
            title:responceJson.data.attributes.title
        }

        this.setState({
            hotelForm: hotelForm,
            aleadyUploadFiles: responceJson.data.attributes.images,
            propertyNameEditStatus: false,
            descriptionEditStatus: false,
            stayMoreThan30NightStatus: false,
            contactDetailsEditStatus: false,
            addressEditStatus: false,
            blockOffDaysEditStatus: false,
            representataiveEditStatus: false,
            selectImageId: 0,
            newelyUploadFiles: [],
            disableActiveStatus: false,
            licienceNumberEditStatus: false,
            titleEditStatus:false

        }, () => {
            this.closeCheckIncheckOutDates()
            this.orderedImages()
        })
    }
    orderedImages = () => {
        let coverImages: { is_cover_image: boolean; }[] = [];
        let nonCoverImages: any = [];
        this.state.aleadyUploadFiles.forEach((image: { is_cover_image: boolean; }) => {
            if (image.is_cover_image) {
                coverImages.push(image);
            } else {
                nonCoverImages.push(image);
            }
        });
        const reorderedImages = coverImages.concat(nonCoverImages);
        this.setState({
            allUploadedFiles: [...reorderedImages, ...this.state.newelyUploadFiles],
            pageLoading: false
        })
    }
    selectedTab = (tab: number) => {
        this.setState({ selectedTab: tab, editPhotosStatus: false }, () => {
            if (this.state.selectedTab === 4) {
                this.getHotelReviews()
            }
            if (this.state.selectedTab === 3) {
                this.getHotelRooms()
                this.getHotel()

            }
        })


    }
    handleopenthreeDots = (id: number | string) => {

        this.setState({ threeDotsStatus: true, selectImageId: id })
    }
    handleclosethreeDots = () => {
        this.setState({ threeDotsStatus: false, selectImageId: 0 })

    }
    editPhotos = () => {
        this.setState({ editPhotosStatus: true })
    }
    openSelectDate = () => {
        this.setState({ openDateStatus: true })
    }
    closeSelectDate = () => {
        this.setState({ openDateStatus: false })
    }

    handleDateChange = (values: DateObject[]) => {
        if (values.length === 2 && values[0] && values[1]) {
            const startDate = values[0].format('DD/MM/YYYY');
            const endDate = values[1].format('DD/MM/YYYY');
            const inputRange = `${startDate} - ${endDate}`;
            this.setState({
                selectedRange: values, dateRange: inputRange,
                startDate: startDate, endDate: endDate,
                openDateStatus: false
            }, () => {
                this.getHotelReviews()
            })


        }
    };
    listingFormEdit = (editType: string) => {
        if (editType === 'propertyName') {
            this.setState({ propertyNameEditStatus: true })

        } else if (editType === 'contact') {
            this.setState({ contactDetailsEditStatus: true })

        }
        else if (editType === 'address') {
            this.setState({ addressEditStatus: true })

        }
        else if (editType === 'description') {
            this.setState({ descriptionEditStatus: true })

        }
        else if (editType === 'stayMoreThan30Night') {
            this.setState({ stayMoreThan30NightStatus: true })

        }
        else if (editType === 'days') {
            this.setState({ blockOffDaysEditStatus: true })

        }
        else if (editType === 'representative') {
            this.setState({ representataiveEditStatus: true })

        }
        else if (editType === 'licience') {
            this.setState({ licienceNumberEditStatus: true })

        }
        else if (editType === 'title') {
            this.setState({ titleEditStatus: true })

        }

    }


    handleListingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let errors: any = {}
        const { name, value } = event.target
        { name == 'propertyName' && this.validationPropertyDescription(value, 'propertyName', 'Property name', errors) }
        { name == 'phoneNumber' && numberValidation(value, 'phoneNumber', 'Phone number', errors) }
        { name == 'emailAddress' && emailValidation(value, 'emailAddress', 'Email address', errors) }
        { name == 'description' && this.validationPropertyDescription(value, 'description', 'Description', errors) }
        { name == 'title' && this.validationPropertyDescription(value, 'title', 'Title', errors) }
        { name === 'houseNumber' && this.validationPropertyDescription(value, 'houseNumber', 'House details', errors) }
        { name === 'additionalAddress' && numeracalCharacterValidation(value, 'additionalAddress', 'Additional Address Information', errors) }
        { name === 'town' && nameValidation(value, 'town', 'Town/City', errors) }
        { name === 'county' && textValidation(value, 'county', 'County', errors) }
        { name === 'licienceNumber' && numeracalCharacterValidation(value, 'licienceNumber', 'Licience Number', errors) }

        this.setState((prevState) => ({
            ...prevState,
            hotelForm: { ...prevState.hotelForm, [name]: value },
            hotelFormErrors: errors,
        }), () => {
            if (name === "houseNumber") {
                this.getLatLongKeys();
            }
        });
    };

    getLatLongKeys = async () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.googleUrl}${this.state.hotelForm.houseNumber}${configJSON.params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        this.getLatLongCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    getLocationPostCode = async (lat:string,long:string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.googleUrlReverse}?lat=${lat}&lon=${long}${configJSON.params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        this.apiCallIdGetLocationPostcode = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };


    handleUploadFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files) {
            const uploadedFiles = Array.from(files).map((file) => ({
                file,
                url: URL.createObjectURL(file),
                cover: false,
                id: uuidv4(),
                // is_cover_image:false
            }));
            this.setState({
                newelyUploadFiles: uploadedFiles,
                allUploadedFiles: [...this.state.aleadyUploadFiles, ...uploadedFiles]
            })
        }


    }

    removeUploadFile = (file: any, index: number) => {
        const { newelyUploadFiles } = this.state;
        const filesFiltered = newelyUploadFiles.filter((f: any) => {
            return file.id !== f.id;
        });
        this.setState({
            newelyUploadFiles: filesFiltered,
            allUploadedFiles: [...this.state.aleadyUploadFiles, ...filesFiltered]
        });

    };

    getHotel = async () => {

        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };
        const hotelID = await getStorageData('BoHotelId')
        this.setState({ pageLoading: true })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.updateHotelEndPoint}/${hotelID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType
        );
        this.apiCallIdgetHotelDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getHotelReviews = async () => {
        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };

        this.setState({ pageLoading: true })

        const hotelID = await getStorageData('BoHotelId')
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getReviewsEndPoint}${hotelID}&start_date=${this.state.startDate}&end_date=${this.state.endDate}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType
        );
        this.apiCallIdgetHotelReviewDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    validationPropertyDescription = (value: string, name: string, key: string, errors: any) => {
        if (!value) {
            errors[name] = `${key} is required`
        }
    }
    validateContactDetailsForm = () => {
        let errors: any = {}
        this.validationPropertyDescription(this.state.hotelForm.propertyName, 'propertyName', 'Property name', errors)
        numberValidation(this.state.hotelForm.phoneNumber, 'phoneNumber', 'Phone number', errors)
        emailValidation(this.state.hotelForm.emailAddress, 'emailAddress', 'Email address', errors)
        this.validationPropertyDescription(this.state.hotelForm.description, 'description', 'Description', errors)
        this.validationPropertyDescription(this.state.hotelForm.title, 'title', 'Title', errors)
        numeracalCharacterValidation(this.state.hotelForm.houseNumber, 'houseNumber', 'House details', errors)
        numeracalCharacterValidation(this.state.hotelForm.licienceNumber, 'licienceNumber', 'Licience Number', errors)
        nameValidation(this.state.hotelForm.town, 'town', 'Town/City', errors)
        textValidation(this.state.hotelForm.county, 'county', 'County', errors)
        return errors
    }

    manageUpdateHotelDetails = () => {
        this.setState({ hotelFormErrors: this.validateContactDetailsForm() }, () => {
            if (Object.keys(this.state.hotelFormErrors).length === 0) {
                this.updateHotelDetails()
            }
        })

    }

    handlePhotosSave = () => {
        const { newelyUploadFiles } = this.state
        if (newelyUploadFiles.length > 0) {
            this.updateHotelDetails()
        } else {
            this.setState({ editPhotosStatus: false })
        }
    }

    updateHotelDetails = async () => {
        let tokenValue = await getStorageData("authToken");
        const hotelID = await getStorageData('BoHotelId')

        const headers = {
            token: tokenValue,
        };
        this.setState({ pageLoading: true })

        const updatebody = new FormData()
        const { hotelForm } = this.state
        this.setState({ pageLoading: true })
        updatebody.append('hotel_id', hotelID)
        updatebody.append('hotel[name]', hotelForm.propertyName)
        updatebody.append('hotel[more_than_30_days]', this.state.stayMoreThan30Days)
        updatebody.append('hotel[phone_number]', hotelForm.phoneNumber)
        updatebody.append('hotel[phone_number_country_code]', hotelForm.phonecountryCode)
        updatebody.append('hotel[email]', hotelForm.emailAddress)
        updatebody.append('hotel[description]', hotelForm.description)
        updatebody.append('hotel[title]', hotelForm.title)
        updatebody.append('hotel[hotel_address_line_1]', hotelForm.houseNumber)
        updatebody.append('hotel[hotel_address_line_2]', hotelForm.additionalAddress)
        updatebody.append('hotel[hotel_city]', hotelForm.town)
        updatebody.append('hotel[hotel_country]', hotelForm.county)
        updatebody.append('hotel[representative_contact_title]', hotelForm.representativeSalutation)
        updatebody.append('hotel[first_name]', hotelForm.representativeFirstName)
        updatebody.append('hotel[surname]', hotelForm.representativeSurname)
        updatebody.append('hotel[representive_country_code]', hotelForm.representativeCountryCode)
        updatebody.append('hotel[reprsentive_phone_number]', hotelForm.representativePhoneNumber)
        updatebody.append("hotel[hotel_postcode]", hotelForm.postCode);
        updatebody.append("hotel[hotel_country_region]", hotelForm.country_region);
        updatebody.append("hotel[latitude]", "" + this.state.coords[0]);
        updatebody.append("hotel[longitude]", "" + this.state.coords[1]);
        updatebody.append("hotel[license_number]", hotelForm.licienceNumber);



        for (let file of this.state.newelyUploadFiles) {
            updatebody.append('hotel[images][]', file.file)
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateHotelEndPoint}/${hotelID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), updatebody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateApiMethodType
        );
        this.apiCallIdUpdateHotel = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleDeletePhotos = (imageId: string | number, actionType: string) => {
        if (this.state.allUploadedFiles.length > 5) {
            this.updateCoverRemovePhoto(imageId, actionType)

        } else {
            this.setState({ selectImageId: -1, toasterStatus: true, toasterText: 'Minimum 5 photos is required ' })

        }

    }

    updateCoverRemovePhoto = async (imageId: string | number, actionType: string) => {
        let tokenValue = await getStorageData("authToken");
        const hotelID = await getStorageData('BoHotelId')

        const headers = {
            token: tokenValue,
        };
        const coverphotoBody = new FormData()

        coverphotoBody.append('hotel_id', hotelID)
        coverphotoBody.append('image_id', imageId.toString())
        this.setState({ pageLoading: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${actionType === 'update' ? configJSON.coverPhotoEndPoint : configJSON.destroyPhotoEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), coverphotoBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),

            `${actionType === 'update' ? configJSON.createApiMethodType : configJSON.deleteMethiodType}`

        );
        this.apiCallIdUpdateDeletePhotos = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // type of rooms
    getHotelRooms = async () => {
        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };

        this.setState({ pageLoading: true })

        const hotelID = await getStorageData('BoHotelId')
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getRoomsEndPoint}=${hotelID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType
        );
        this.apiCallIdRommDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    openAddRoomType = () => {
        this.setState({ addRoomType: true })
    }
    closeAddRoomType = () => {
        this.setState({
            addRoomType: false, validationErrors: {
                roomName: "",
                roomDescription: "",
                roomPriceError: "",
                propertiesError: "",
                petsError: ""
            },
            RoomFields: {
                roomName: "",
                roomDescription: ""
            },
            propertiesCount: 0,
            petsCount: 0,
            roomPricesArray: []
        })
    }

    addProperties = () => {
        this.setState(prevState => ({
            propertiesCount: prevState.propertiesCount + 1,
            validationErrors: { ...this.state.validationErrors, propertiesError: "" }


        }));
    }
    minusProperties = () => {
        if (this.state.propertiesCount > 0) {
            this.setState(prevState => ({
                propertiesCount: prevState.propertiesCount - 1,



            }));

        } else if (this.state.propertiesCount === 0) {
            this.setState(prevState => ({
                validationErrors: { ...this.state.validationErrors, propertiesError: "Please specify the number of rooms at the property" }
            }));

        }
    }
    addPets = () => {
        this.setState(prevState => ({
            petsCount: prevState.petsCount + 1,
            validationErrors: { ...this.state.validationErrors, petsError: "" },
            roomPricesArray: [
                ...prevState.roomPricesArray,
                {
                    name: `${this.numberToWord(prevState.petsCount + 1)}`,
                    price: null,
                },
            ].filter((room, index, self) =>
                index === self.findIndex((r) => r.name === room.name)
            ),


        }));
    }
    minusPets = () => {
        const updatedRoomPricesArray = this.state.roomPricesArray.slice(0, -1);
        if (this.state.petsCount > 0) {
            this.setState(prevState => ({
                petsCount: prevState.petsCount - 1,

                roomPricesArray: updatedRoomPricesArray,
                validationErrors: { ...this.state.validationErrors, petsError: "Please specify the maximum number of pets in a room" }



            }));

        }
    }
    numberToWord = (number: number) => {
        const words = ["one", "two", "three", "four", "five", "six",
            "seven", "eight", "nine", "ten", "", "eleven", "twelve", "thirteen",
            "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen", "twenty",
            "twenty-one", "twenty-two", "twenty-three", "twenty-four", "twenty-five"];

        return words[number - 1] || number.toString();
    };
    handlePriceChanges = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>, petType: string) => {

        const regex = configJSON.regex;
        const priceValue = event.target.value.replace("£", "").trim();

        this.setState((prevState) => {
            const updatedRoomPricesArray = prevState.roomPricesArray.map((room) => {
                if (room.name === petType && regex.test(priceValue)) {
                    return {
                        ...room,
                        price: priceValue,
                    };
                }
                return room;
            });

            return {
                ...prevState,
                errorMsgModel: "",
                roomPricesArray: updatedRoomPricesArray,
                validationErrors: { ...this.state.validationErrors, roomPriceError: "" }
            };
        });
    };

    handleSelectPetType = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ petType: (event.target as HTMLInputElement).value })
    }

    handleAddRoomDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target
        { name === "roomName" && this.validateRoomName(value) }
        { name === "roomDescription" && this.validateRoomDescription(value) }
        this.setState({ RoomFields: { ...this.state.RoomFields, [name]: value } })
    }

    validateRoomName = (value: string) => {
        let nameError = ""

        if (!value.trim()) {
            nameError = configJSON.roomErrorMessages.roomTypeEmpty;
        }

        this.setState(prevState => ({
            validationErrors: {
                ...prevState.validationErrors,
                roomName: nameError,

            }
        }));

    }
    validateRoomDescription = (value: string) => {
        let descError = ""

        if (!value.trim()) {
            descError = configJSON.roomErrorMessages.roomTypeEmpty;
        }

        this.setState(prevState => ({
            validationErrors: {
                ...prevState.validationErrors,
                roomDescription: descError,

            }
        }));

    }

    validateRoomFields = () => {
        const { roomName, roomDescription } = this.state.RoomFields;
        let nameError = "";
        let descriptionError = "";
        let priceError = "";
        let propertiesError = '';
        let petsError = ""

        // Regex patterns for validation


        if (!roomName.trim()) {
            nameError = configJSON.roomErrorMessages.roomTypeEmpty;
        }

        // Validate room description
        if (!roomDescription.trim()) {
            descriptionError = configJSON.roomErrorMessages.descriptionEmpty;
        }
        if (this.state.propertiesCount === 0) {
            propertiesError = 'Please specify the number of rooms at the property'
        }
        if (this.state.petsCount === 0) {
            petsError = 'Please specify the maximum number of pets in a room'
        }


        // Check if any price is missing
        const missingPrices = this.state.roomPricesArray.filter(room => room.price === null || room.price === undefined || room.price === "");
        if (missingPrices.length > 0) {
            priceError = configJSON.roomErrorMessages.roomPricesEmpty;
        }

        // Update state with validation errors
        this.setState(prevState => ({
            validationErrors: {
                ...prevState.validationErrors,
                roomName: nameError,
                roomDescription: descriptionError,
                roomPriceError: priceError,
                propertiesError: propertiesError,
                petsError: petsError
            }
        }));

        return nameError === "" && descriptionError === "" && priceError === "" && petsError === "" && propertiesError === "";
    }
    handleCreateRoom = () => {
        // Validate room fields
        const fieldsValid = this.validateRoomFields();
        if (!fieldsValid) {
            return; // Stop further execution
        } else {

            this.createRoom()
        }
    }


    createRoom = async () => {
        // Calculate the total catRoomDog
        const header = {
            "Content-Type": "application/json",
        };
        const hotelID = await getStorageData('BoHotelId')
        const profileIdBo = await getStorageData('profileIdBo')


        const httpBody = {

            "room": {
                "description": this.state.RoomFields.roomDescription,
                "room_for": this.state.petType,
                "price": Number(this.state.roomPricesArray?.length > 0 ? this.state.roomPricesArray[0]?.price : 0),
                "max_capacity": this.state.propertiesCount,
                "room_type": this.state.RoomFields.roomName,
                "pet_count": this.state.petsCount,
                "add_pet": this.state.petsCount,
                "pet_room_prices": this.state.roomPricesArray?.map((value: { price: number }) => value?.price) || [],
                "account_id": profileIdBo,
                "hotel_id": hotelID,
                "num_rooms": this.state.propertiesCount,
                "num_dogs": this.state.petType === 'Dog' ? this.state.petsCount * this.state.propertiesCount : 0,
                "num_cats": this.state.petType === 'Cat' ? this.state.petsCount * this.state.propertiesCount : 0
            }


        };
        this.setState({ pageLoading: true })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_custom_form/rooms"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        this.apiCallIdCreateRoom = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    updateRoomNameDesctiption = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>, index: number) => {

        event.persist()
        const { value, name } = event.target;

        this.setState((prev) => ({
            chnagedRoomData: prev.chnagedRoomData.map((room, i) => {
                if (i === index) {
                    return { ...room, [name]: value };
                }
                return room;
            }),
            roomErrors: prev.roomErrors.map((error, i) => {
                if (i === index) {
                    // Reset error if the field is not empty anymore
                    return { ...error, [name]: value ? '' : error[name] };
                }
                return error;
            }),
        }));

    }

    minusUpdateRooms = (eleindex: number, type: string) => {
        this.setState((prev) => ({
            chnagedRoomData: prev.chnagedRoomData.map((room: RoomManipulate, index: number) => {
                if (eleindex === index) {

                    if (type === 'rooms' && room.num_rooms > 0) {
                        return { ...room, num_rooms: room.num_rooms - 1 };
                    } else if (type === 'pets' && room.pet_count) {
                        return { ...room, pet_count: room.pet_count - 1, pet_room_prices: room.pet_room_prices.slice(0, -1) };
                    }
                }
                return room;
            }),
        }));
    }

    addUpdateRooms = (eleIndex: number, type: string) => {
        this.setState((prev) => ({
            chnagedRoomData: prev.chnagedRoomData.map((room: RoomManipulate, index: number) => {
                if (eleIndex === index) {
                    if (type === 'rooms') {
                        return { ...room, num_rooms: room.num_rooms + 1 };
                    } else if (type === 'pets') {
                        return {
                            ...room, pet_count: room.pet_count + 1, pet_room_prices: [
                                ...room.pet_room_prices, null
                            ]
                        };


                    }
                }
                return room;
            }),
        }));
    }

    updatePetPrices = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>, index: number, roomId: string | number) => {

        const priceValue = event.target.value.replace("£", "").trim();
        const regex = configJSON.regex;


        this.setState(prevState => ({
            chnagedRoomData: prevState.chnagedRoomData.map(room => {
                if (room.room_id === roomId && regex.test(priceValue)) {
                    const pet_room_prices = [...room.pet_room_prices];
                    pet_room_prices[index] = priceValue;
                    return { ...room, pet_room_prices };
                }
                return room;
            }),
        }));


    };

    editAdditionalServices = () => {
        this.setState({ additionalServicesStatus: true })
    }

    handleDeleteService = (id: number | string, isCustom: boolean) => {
        const { additionalServicesList, ownServices } = this.state
        let deletedService: any = undefined;

        if (isCustom) {
            deletedService = ownServices.find(service => service.id === id);
        } else {
            deletedService = additionalServicesList.find(service => service.id === id);
        }
        if (isCustom) {
            this.setState(prevState => ({
                ownServices: prevState.ownServices.filter(service => service.id !== id),
                deletedOwnServices: [...prevState.deletedOwnServices, deletedService]
            }))
        } else {
            this.setState(prevState => ({
                additionalServicesList: prevState.additionalServicesList.filter(service => service.id !== id),
                deletedAdditionalServices: [...prevState.deletedAdditionalServices, deletedService]
            }));
        }
    };

    handleServiceNameChange = (id: number, newName: string) => {
        const { ownServices } = this.state;
        const editedServiceIndex = ownServices.findIndex(service => service.id === id);
        if (editedServiceIndex !== -1) {
            const editedService = { ...ownServices[editedServiceIndex], name: newName };
            if (ownServices[editedServiceIndex].type === 'new') {
                const editedOwnServices = [...this.state.newelyCreateOwnServices]
                editedOwnServices[editedServiceIndex] = editedService;
                this.setState(prevState => ({
                    // editedOwnServices,

                    ownServices: prevState.ownServices.map(service => service.id === editedService.id ? editedService : service)
                }));
            } else {
                const editedOwnServices = [...this.state.editedOwnServices]

                editedOwnServices[editedServiceIndex] = editedService;
                this.setState(prevState => ({
                    editedOwnServices,
                    ownServices: prevState.ownServices.map(service => service.id === editedService.id ? editedService : service)
                }));
            }

        }
    };






    handleSaveUpdateRoom = (roomDetails: RoomManipulate) => {
        let roomErrors: RootDetails[] = [];
        // Check for empty fields and validate against regex for each room
        this.state.chnagedRoomData.forEach((room: RootDetails, index) => {
            let errors: any = {}
            if (room.room_type === "") {
                errors.room_type = configJSON.roomErrorMessages.roomTypeEmpty;
            }
            if (room.description === "") {
                errors.description = configJSON.roomErrorMessages.descriptionEmpty;
            }
            if (room.num_rooms === 0) {
                errors.num_roomsError = 'Please specify the number of rooms at the property'

            }
            if (room.pet_count === 0) {
                errors.petCountError = 'Please specify the maximum number of pets in a room'

            }


            const emptyPrices = room.pet_room_prices.filter(price => price === null || price === undefined || price === "");
            if (emptyPrices.length > 0) {
                errors.priceError = configJSON.roomErrorMessages.roomPricesEmpty;
            }

            roomErrors.push(errors);
        });

        // Update state with roomErrors
        this.setState({ roomErrors });

        // Check if there are any empty fields
        const hasErrors = roomErrors.some(errors => Object.keys(errors).length > 0);
        if (hasErrors) {
            // Stop further execution if there are errors
            return;
        }
        this.updateRoomDetails(roomDetails)
    }

    updateRoomDetails = async (roomDetails: RoomManipulate) => {
        const header = {
            "Content-Type": "application/json",
        };
        const hotelID = await getStorageData('BoHotelId')
        this.setState({ pageLoading: true })
        const httpBody = {

            "room": {
                "description": roomDetails.description,
                "room_for": roomDetails.room_for,
                "price": Number(roomDetails.pet_room_prices?.length > 0 ? roomDetails.pet_room_prices[0] : 0),
                "max_capacity": roomDetails.num_rooms,
                "room_type": roomDetails.room_type,
                "pet_count": roomDetails.pet_count,
                "add_pet": roomDetails.pet_count,
                "pet_room_prices": roomDetails.pet_room_prices?.map((value: string | number) => value) || [],
                "room_id": roomDetails.room_id,
                "hotel_id": hotelID,
                "num_rooms": roomDetails.num_rooms,
                "num_dogs": roomDetails.room_for === 'Dog' ? roomDetails.pet_count * roomDetails.num_rooms : 0,
                "num_cats": roomDetails.room_for === 'Cat' ? roomDetails.pet_count * roomDetails.num_rooms : 0,
                "status": roomDetails.status
            }
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_custom_form/rooms/${hotelID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        this.apiCalIdUpdateRoomDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    handleUpdateOwnServices = () => {
        const { additionalServicesList, ownServices } = this.state
        const inputErrors: { [key: string]: string } = {};
        const ownServiceInputErrors: { [key: string]: string } = {};

        additionalServicesList.forEach((service, index) => {
            if (service.price === "0") {
                inputErrors[`price_${service.id}`] = "Service price greater than 0.";
            }
        });
        ownServices.forEach((service, index) => {
            if (service.price === "0") {
                ownServiceInputErrors[`price_${service.id}`] = "Service price greater than 0.";
            }
            if (service.name.trim() === '') {
                ownServiceInputErrors[`service_${service.id}`] = "Service name cannot be empty.";
            }
        });
        // Set error message state
        this.setState({ additionalInputErrors: inputErrors, ownServiceInputErrors: ownServiceInputErrors });

        // If there are errors, stop further execution
        if (Object.keys(inputErrors).length > 0) {
            return;
        }
        if (Object.keys(ownServiceInputErrors).length > 0) {
            return;
        }


        this.updateAdditionalServices();
        {
            this.state.newelyCreateOwnServices && this.state.newelyCreateOwnServices.length > 0 &&
                this.updateownServices()
        }

    }

    updateownServices = async () => {
        const header = {
            "Content-Type": "application/json",
        };
        const hotelID = await getStorageData('BoHotelId')
        this.setState({ pageLoading: true })
        const updatedOwnService = this.state.newelyCreateOwnServices.map(service => ({
            name: service.name,
            service_provided_type: service.service_provided_type,
            price: service.price.toString(),
            charge_type: service.charge_type
        }));
        const httpBody = {
            "hotel_id": hotelID,
            "hotel": {
                "own_service": updatedOwnService
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_custom_form/hotels/create_your_own_services'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        this.apiCalIdUpdateRoomDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    updateAdditionalServices = async () => {
        const hotelID = await getStorageData('BoHotelId')
        const token = await getStorageData('authToken')
        const header = {
            "token": token
        };
        let updateHotelFormData = new FormData();
        updateHotelFormData.append('hotel_id', hotelID)
        this.state.deletedOwnServices.length > 0 && this.state.deletedOwnServices.forEach((ownService: CreateYourOwnService) => {
            updateHotelFormData.append('hotel[delete_create_your_own_services][]', ownService.id.toString())
        })
        this.state.deletedAdditionalServices.length > 0 && this.state.deletedAdditionalServices.forEach((service: HotelAdditionalService) => {
            updateHotelFormData.append('hotel[delete_hotel_additional_services][]', service.id.toString())
        })
        this.state.editedOwnServices.length > 0 && this.state.editedOwnServices.forEach((service: CreateYourOwnService) => {
            if (!service) {
                // Handle the case where the service object itself is undefined
                return;
            }
            updateHotelFormData.append('hotel[create_your_own_services][][charge_type]', service.charge_type)
            updateHotelFormData.append('hotel[create_your_own_services][][id]', service.id.toString())
            updateHotelFormData.append('hotel[create_your_own_services][][name]', service.name)
            updateHotelFormData.append('hotel[create_your_own_services][][price]', service.price.toString())
            updateHotelFormData.append('hotel[create_your_own_services][][service_provided_type]', service?.service_provided_type)
        })
        this.state.editedAdditionalServices.length > 0 && this.state.editedAdditionalServices.forEach((service: HotelAdditionalService) => {
            if (!service) {
                // Handle the case where the service object itself is undefined
                return;
            }
            updateHotelFormData.append('hotel[hotel_additional_services][][charge_type]', service.charge_type)
            updateHotelFormData.append('hotel[hotel_additional_services][][id]', service.id.toString())
            updateHotelFormData.append('hotel[hotel_additional_services][][name]', service.name)
            updateHotelFormData.append('hotel[hotel_additional_services][][price]', service.price.toString())
            updateHotelFormData.append('hotel[hotel_additional_services][][service_provided_type]', service?.service_provided_type)
        })

        this.state.newlyAddedServices.forEach((value: HotelAdditionalService) => {
            if (value.type === 'new') {
                updateHotelFormData.append(`hotel[additional_services][${value.id}]`, JSON.stringify({ "price": value.price, "service_provided_type": value.service_provided_type, charge_type: value.charge_type }))
            }

        });

        this.setState({ pageLoading: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateHotelEndPoint}/${hotelID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            updateHotelFormData
        );

        this.apiCalIdUpdateServicesDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    handleRoomEdit = (hotelId: number) => {
        this.setState({ selectedHotelIdEdit: hotelId })
    };

    openAdditionalServices = () => {
        this.setState({ newAdditionalServicesStatus: true }, () => {
            this.getAdditionalServices()
        })
    }
    closeAdditionalServices = () => {
        this.setState({ newAdditionalServicesStatus: false })

    }

    getAdditionalServices = async () => {
        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };

        // this.setState({ pageLoading: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.additionalServicesEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType
        );
        this.apiCallIdgetAdditionalServices = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handlePriceChange = (id: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const regex = configJSON.regex;
        const priceValue = event.target.value.replace("£", "").trim();
        if(regex.test(priceValue)) {
        const numericPrice = priceValue[0] === "0"?priceValue.slice(1):priceValue || "0";
        const additionalService = this.state.additionalServicesList.find(service => service.id === id);
        const ownService = this.state.ownServices.find(service => service.id === id);
        if (additionalService) {
            this.handleAdditionalChange(numericPrice, id, additionalService.service_provided_type, additionalService.charge_type);
        }
        if (ownService) {
            this.handleOwnServicesChange(numericPrice, id, ownService.service_provided_type, ownService.charge_type);
        }
      }
    }
    handleAdditionalChange = (numericPrice: string, id: number, serviceProvidedType: string, chargeType: string) => {
        const { additionalServicesList } = this.state;
        const editedServiceIndex = additionalServicesList.findIndex(service => service.id === id);
        if (editedServiceIndex !== -1) {
            const editedService = { ...additionalServicesList[editedServiceIndex], price: numericPrice, service_provided_type: serviceProvidedType, charge_type: chargeType };
            const editedOwnServices = [...this.state.newlyAddedServices];
            editedOwnServices[editedServiceIndex] = editedService;
            if (editedServiceIndex !== -1 && additionalServicesList[editedServiceIndex].type === 'new') {
                this.setState(prevState => ({
                    additionalServicesList: prevState.additionalServicesList.map(service => service.id === editedService.id ? editedService : service),
                    newlyAddedServices: prevState.additionalServicesList.map(service => service.id === editedService.id ? editedService : service),

                }));
            } else {
                const editedService = { ...additionalServicesList[editedServiceIndex], price: numericPrice, service_provided_type: serviceProvidedType, charge_type: chargeType };
                const editedAdditionalServices = [...this.state.editedAdditionalServices];
                editedAdditionalServices[editedServiceIndex] = editedService;
                this.setState(prevState => ({
                    editedAdditionalServices,
                    additionalServicesList: prevState.additionalServicesList.map(service => service.id === editedService.id ? editedService : service)
                }));
            }


        }
    }
    handleOwnServicesChange = (numericPrice: string, id: number, serviceProvidedType: string, chargeType: string) => {
        const { ownServices } = this.state;
        const editedServiceIndex = ownServices.findIndex(service => service.id === id);
        if (editedServiceIndex !== -1) {
            const editedService = { ...ownServices[editedServiceIndex], price: numericPrice, service_provided_type: serviceProvidedType, charge_type: chargeType };
            const editedOwnServices = [...this.state.newelyCreateOwnServices];
            editedOwnServices[editedServiceIndex] = editedService;
            if (editedServiceIndex !== -1 && ownServices[editedServiceIndex].type === 'new') {
                this.setState(prevState => ({

                    ownServices: prevState.ownServices.map(service => service.id === editedService.id ? editedService : service),
                    newelyCreateOwnServices: prevState.newelyCreateOwnServices.map(service => service.id === editedService.id ? editedService : service),

                }));
            } else {
                const editedService = { ...ownServices[editedServiceIndex], price: numericPrice, service_provided_type: serviceProvidedType, charge_type: chargeType };
                const editedOwnServices = [...this.state.editedOwnServices];
                editedOwnServices[editedServiceIndex] = editedService;
                this.setState(prevState => ({
                    editedOwnServices,
                    ownServices: prevState.ownServices.map(service => service.id === editedService.id ? editedService : service)
                }));
            }


        }
    }

    handleSelectUpdatePetType = (event: React.ChangeEvent<HTMLInputElement>, typeIndex: number) => {
        event.persist()
        this.setState((prev) => ({
            chnagedRoomData: prev.chnagedRoomData.map((room, index) => {
                if (index === typeIndex) {
                    return { ...room, room_for: (event.target as HTMLInputElement).value };
                }
                return room;
            }),
        }));
    }

    handleSelectSevices = (service: ServiceItem) => {
        let randomID = service.attributes.id
        const serviceItem = {
            id: randomID,
            name: service.attributes.service_name,
            price: "0",
            hotel_id: 0,
            additional_service_id: 0,
            service_provided_type: "Daily",
            charge_type: "All",
            type: 'new',
            alreadyCreated: false
        }
        let newService = {
            id: this.uuidToNumber(),
            name: "",
            price: "0",
            type: 'new',
            additional_service_id: 0,
            service_provided_type: "Daily",
            charge_type: "All",
            hotel_id: 0,
            alreadyCreated: false
        };

        if (this.shouldAddNewService(service, randomID)) {
            this.setState({
                newelyCreateOwnServices: [...this.state.newelyCreateOwnServices, newService],
                ownServices: [...this.state.ownServices, newService]
            }, () => {
                this.closeAdditionalServices()
            });
        } else {
            this.setState({
                newlyAddedServices: [...this.state.newlyAddedServices, serviceItem],
                additionalServicesList: [...this.state.additionalServicesList, serviceItem]
            }, () => {
                this.closeAdditionalServices()
            });
        }


    }



    shouldAddNewService(service: ServiceItem, randomID: number): boolean {
        const { service_name } = service.attributes;
        const keywords = ['create', 'specify'];

        return randomID === 9 || keywords.some(keyword =>
            service_name.toLowerCase().includes(keyword)
        );
    }



    uuidToNumber = () => {
        const uuid = uuidv4().replace(/-/g, ''); // remove dashes from UUID
        return parseInt(uuid, 16); // convert hexadecimal UUID to decimal number
    }
    openNameTypeDropDowns = (type: string, repId: number) => {
        { type === 'salutation' && this.setState({ repId: repId }) }
        { type === 'addressCountry' && this.setState({ addressCountryStatus: true }) }

        {
            type === 'repCountryCode' && this.setState({ repCountryCode: repId })
            { type === 'contactCountry' && this.setState({ countryCodeStatus: true }) }

        }
    }
    closeNameTypeDropDowns = (type: string) => {
        { type === 'salutation' && this.setState({ repId: 0 }) }
        { type === 'addressCountry' && this.setState({ addressCountryStatus: false }) }
        { type === 'repCountryCode' && this.setState({ repCountryCode: 0 }) }
        { type === 'contactCountry' && this.setState({ countryCodeStatus: false }) }
    }
    handleSelectNameType = (value: string, index: number) => {
        const updatedObjects = [...this.state.representativeDetails];
        updatedObjects[index]['representative_contact_title'] = value as string;
        this.setState({ representativeDetails: updatedObjects }, () => {
            this.closeNameTypeDropDowns('salutation')
        })
    }
    handleSelectCountryName = (value: string, type: string, index: number) => {
        if (type === 'repCountryCode') {
            const updatedObjects = [...this.state.representativeDetails];
            updatedObjects[index]['representive_country_code'] = value as string;
            this.setState({ representativeDetails: updatedObjects }, () => {
                this.closeNameTypeDropDowns('repCountryCode')
            })
        }
        if (type === 'contactCountry') {
            this.setState({ hotelForm: { ...this.state.hotelForm, phonecountryCode: value } }, () => {
                this.closeNameTypeDropDowns('contactCountry')
            })
        }

    }
    handleSelectAdressCountry = (value: string, type: string) => {
        this.setState({ hotelForm: { ...this.state.hotelForm, country_region: value } }, () => {
            this.closeNameTypeDropDowns(type)
        })

    }
    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ stayMoreThan30Days: event.target.value });
    };
    openMoreDescription = () => {
        this.setState({ moreDescription: true })
    }
    closeMoreDescription = () => {
        this.setState({ moreDescription: false })
    }
    openBlockedCalander = (dayId: number) => {
        this.setState({ blockedCalenderStatus: dayId })
    }

    closeBlockedCalander = () => {
        this.setState({ blockedCalenderStatus: -1 })
    }

    handleDateChangeBlockOffDays = (index: number, values: DateObject[],) => {
        const updatedObjects: any = [...this.state.blockOffDays];


        if (values.length === 2 && values[0] && values[1]) {
            const startDate = values[0].format('DD/MM/YYYY');
            const endDate = values[1].format('DD/MM/YYYY');
            updatedObjects[index]['fromDate'] = startDate;
            updatedObjects[index]['toDate'] = endDate;
            updatedObjects[index]['selectedBlockOffDateRange'] = values;
            this.setState({
                blockOffDays: updatedObjects
            }, () => {
                this.closeBlockedCalander()
            })


        }
    };

    updateBlockOffDays = async (days: BlockOffDays) => {
        const hotelID = await getStorageData('BoHotelId');
        let tokenValue = await getStorageData("authToken");

        const header = {
            token: tokenValue
        }
        const updateBlockOffDays = new FormData();
        updateBlockOffDays.append('hotel_id', hotelID)
        updateBlockOffDays.append('from_date', days.fromDate.toString())
        updateBlockOffDays.append('to_date', days.toDate)
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        let endPoint
        let MethodType
        if (days.type != "new") {
            endPoint = `${configJSON.blockOffDaysEndPoint}/${days.id}`
            MethodType = 'PUT'
        }
        else {
            endPoint = `${configJSON.blockOffDaysEndPoint}`
            MethodType = 'POST'
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),

            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            MethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), updateBlockOffDays
        );

        this.apiCalIdUpdateRoomDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };
    delteBlockOffDays = (days: BlockOffDays) => {
        if (days.type !== 'new') {
            this.deleteBlockOffDays(days)
        }
        else {

            const updatedBlockOffDays = this.state.blockOffDays.filter(item => item.id !== days.id);
            this.setState({ blockOffDays: updatedBlockOffDays });
        }

    }

    deleteBlockOffDays = async (days: BlockOffDays) => {
        const hotelID = await getStorageData('BoHotelId');
        let tokenValue = await getStorageData("authToken");

        const header = {
            token: tokenValue
        }
        const updateBlockOffDays = new FormData();
        updateBlockOffDays.append('hotel_id', hotelID)
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),

            `${configJSON.blockOffDaysEndPoint}/${days.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethiodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), updateBlockOffDays
        );

        this.apiCalIdUpdateRoomDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    getRepresentativeDetails = async () => {
        const hotelID = await getStorageData('BoHotelId');
        let tokenValue = await getStorageData("authToken");

        const header = {
            token: tokenValue
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.setState({ pageLoading: true })
        let endPoint = `${configJSON.getRepresentativeDetails}/${hotelID}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );

        this.apiCallIdGetRepresentativeDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    handleDeleteRepresentative = (detail: RepresentativeDetail) => {
        if (detail.type === "new") {
            const updatedDetails = this.state.representativeDetails.filter(item => item.id !== detail.id);
            this.setState({ representativeDetails: updatedDetails });

        } else {
            this.deleteRepresentativeDetails(detail.id)
        }
    }
    deleteRepresentativeDetails = async (RepId: number) => {
        let tokenValue = await getStorageData("authToken");

        const header = {
            "content-type": configJSON.dashboarContentType,
            token: tokenValue
        }
        this.setState({ pageLoading: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        let endPoint = `${configJSON.updateDeleteRepresentativeDetails}/${RepId}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'DELETE'
        );

        this.apiCallIdUpdateDeleteReservationDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };
    handleUpdateRepresentativeDetails = (repDetails: RepresentativeDetail) => {
        const representativeFieldsValid = this.validateEditRepresentativeFields();
        if (!representativeFieldsValid) {
            return;
        } else {
            if (repDetails.type === 'new') {
                this.saveRepresentativeDetails(repDetails)
            } else {

                this.updateRepresentativeDetails(repDetails)
            }
        }
    }
    updateRepresentativeDetails = async (repDetails: RepresentativeDetail,) => {
        let tokenValue = await getStorageData("authToken");

        const header = {
            "content-type": configJSON.dashboarContentType,
            token: tokenValue
        }
        const repBody = {
            "data": {
                "first_name": repDetails.first_name,
                "surname": repDetails.surname,
                "reprsentive_phone_number": repDetails.reprsentive_phone_number,
                "representive_country_code": repDetails.representive_country_code,
                "representative_contact_title": repDetails.representative_contact_title,
                'email': repDetails.email
            }
        }
        this.setState({ pageLoading: true })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        let endPoint = `${configJSON.updateDeleteRepresentativeDetails}/${repDetails.id}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PATCH'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(repBody)
        );

        this.apiCallIdUpdateDeleteReservationDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    handleInputChange = (
        index: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string
    ) => {
        const { value } = event.target;
        const updatedObjects: any = [...this.state.representativeDetails];
        updatedObjects[index][key] = value;
        let updatedErrors = [...this.state.repErrors];
        if (!updatedErrors[index]) {
            updatedErrors[index] = {};
        }
        const phoneRegex = /^\d+$/;
        let error = '';

        if (key === 'first_name' || key === 'surname') {
            error = this.validateNameField(key, value);
        }
        if (key === 'reprsentive_phone_number') {
            if (!value) {
                error = "Phone number is required";
            } else if (!value.match(phoneRegex)) {
                error = "Please enter a valid phone number that contains numbers";
            }
        }
        if (key === 'email') {
            error = this.validateEmailField(value);
        }

        if (error) {
            updatedErrors[index][key] = error;
        } else {
            delete updatedErrors[index][key];
        }

        this.setState({ representativeDetails: updatedObjects, repErrors: updatedErrors });
    };

    validateNameField = (key: string, value: string): string => {
        const nameRegex = /^[a-zA-Z\s]+$/;
        if (!value) {
            return key === 'first_name' ? "First name is required" : "Surname is required";
        } else if (!value.match(nameRegex)) {
            return "Please enter alphabetical characters only";
        }
        return '';
    };

    validateEmailField = (value: string) => {
        if (!value) {
            return "Email is required";
        } else if (!value.match(configJSON.emailRegex)) {
            return configJSON.emailError;
        }
        return '';
    };

    validateEditRepresentativeFields = () => {
        const { representativeDetails, repErrors } = this.state;
        const newErrors: any = [...repErrors];
        let hasEmptyRepresentative = false;
        representativeDetails.forEach((rep: RepresentativeDetail, index) => {
            const errorsForRep: RepresentativeDetail = {
                id: 0,
                representative_contact_title: "",
                first_name: "",
                surname: "",
                email: "",
                representive_country_code: "",
                reprsentive_phone_number: "",
                hotel_id: 0
            }
            if (!rep.first_name.trim()) {
                errorsForRep.first_name = 'First name is required';
                hasEmptyRepresentative = true
            }
            if (!rep.surname.trim()) {
                errorsForRep.surname = 'Surname is required';
                hasEmptyRepresentative = true

            }
            if (!rep.reprsentive_phone_number.trim()) {
                errorsForRep.reprsentive_phone_number = 'Phone number is required';
                hasEmptyRepresentative = true

            }
            if (!rep.email.trim()) {
                errorsForRep.email = 'Email is required';
                hasEmptyRepresentative = true
            }
            else if (!configJSON.emailRegex.test(rep.email)) {
                errorsForRep.email = 'Please enter valid email address';
                hasEmptyRepresentative = true;
            }
            newErrors[index] = errorsForRep;
        });
        this.setState({ repErrors: newErrors });
        return !hasEmptyRepresentative;
    }

    addNewRepresentative = () => {
        let newRepresentative = {
            id: this.uuidToNumber(),
            representative_contact_title: "Mr.",
            first_name: "",
            surname: "",
            email: "",
            representive_country_code: "United Kingdom (+44)",
            reprsentive_phone_number: "",
            hotel_id: 0,
            type: "new"
        }
        this.setState({
            representativeDetails: [...this.state.representativeDetails, newRepresentative],
        });

    }

    saveRepresentativeDetails = async (repDetails: RepresentativeDetail,) => {
        let tokenValue = await getStorageData("authToken");
        const hotelID = await getStorageData('BoHotelId');

        const header = {
            "content-type": configJSON.dashboarContentType,
            token: tokenValue
        }
        const repBody = {
            "data": {
                "first_name": repDetails.first_name,
                "surname": repDetails.surname,
                "email": repDetails.email,
                "reprsentive_phone_number": repDetails.reprsentive_phone_number,
                "representive_country_code": repDetails.representive_country_code,
                "representative_contact_title": repDetails.representative_contact_title,
                'hotel_id': hotelID
            }
        }
        this.setState({ pageLoading: true })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        let endPoint = 'bx_block_custom_form/seller_accounts/representative_details'
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'POST'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(repBody)
        );

        this.apiCallIdUpdateDeleteReservationDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };


    addBlockOffDays = () => {
        let newBlockOffDays = {
            id: this.uuidToNumber(),
            fromDate: "",
            toDate: '',
            selectedBlockOffDateRange: [],
            type: "new"
        }
        this.setState({
            blockOffDays: [...this.state.blockOffDays, newBlockOffDays],
        });
    }

    closeToaster = () => {
        this.setState({ toasterStatus: false })
    }

    handleServiceType = (value: string, id: number) => {
        this.setState(prevState => {
            const updatedAdditionalServicesList = prevState.additionalServicesList.map(service => {
                if (service.id === id) {
                    const updatedService = { ...service, service_provided_type: value };
                    return updatedService;
                }
                return service;
            });
            const updatedOwnServices = prevState.ownServices.map(service => {
                if (service.id === id) {
                    const updatedService = { ...service, service_provided_type: value };
                    return updatedService;
                }
                return service;
            });

            return {
                additionalServicesList: updatedAdditionalServicesList,
                ownServices: updatedOwnServices
            };
        }, () => {
            const additionalService = this.state.additionalServicesList.find(service => service.id === id);
            const ownService = this.state.ownServices.find(service => service.id === id);

            if (additionalService) {
                this.handleAdditionalChange(additionalService.price, additionalService.id, additionalService.service_provided_type, additionalService.charge_type);
            }

            if (ownService) {
                this.handleOwnServicesChange(ownService.price, ownService.id, ownService.service_provided_type, ownService.charge_type);
            }
        });
    };

    handleChargeType = (value: string, id: number) => {
        this.setState(prevState => {
            const updatedAdditionalServicesList = prevState.additionalServicesList.map(service => {
                if (service.id === id) {
                    const updatedCargeType = { ...service, charge_type: value };
                    return updatedCargeType;
                }
                return service;
            });
            const updatedOwnServices = prevState.ownServices.map(service => {
                if (service.id === id) {
                    const updatedCargeType = { ...service, charge_type: value };
                    return updatedCargeType;
                }
                return service;
            });

            return {
                additionalServicesList: updatedAdditionalServicesList,
                ownServices: updatedOwnServices
            };
        }, () => {
            const myAdditionalServices = this.state.additionalServicesList.find(service => service.id === id);
            const myOwnService = this.state.ownServices.find(service => service.id === id);

            if (myAdditionalServices) {
                this.handleAdditionalChange(myAdditionalServices.price, myAdditionalServices.id, myAdditionalServices.service_provided_type, myAdditionalServices.charge_type);
            }

            if (myOwnService) {
                this.handleOwnServicesChange(myOwnService.price, myOwnService.id, myOwnService.service_provided_type, myOwnService.charge_type);
            }
        });
    };

    getPosition = (hotel: { attributes: { latitude: number; longitude: number; }; }) => {
        return hotel?.attributes?.latitude && hotel?.attributes?.longitude;
    };


    getDayCareServiceData = async () => {
        const hotelId = await getStorageData('BoHotelId');
        this.dayCareServiceCallId = await this.apiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.dashboarApiMethodType,
            endPoint: `${configJSON.dayCareEndpoint.replace("{hotelId}", hotelId)}`
        })
    };

    apiCall = async (apiData: APIPayloadType) => {
        const { method, endPoint, body, type } = apiData;

        let token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    dayCareApiCall = (response: RootObject) => {
        const listObject = response.day_care_services.data[0].attributes;
        this.setState({
            dayCarePriceList: response,
            dayCareId: response.day_care_services.data[0].id,
            spaces: String(response.day_care_services.data[0].attributes.spaces),
            oneTimePrice: [
                listObject.price_for_one_day,
                listObject.price_for_two_days,
                listObject.price_for_three_days,
                listObject.price_for_four_days,
                listObject.price_for_five_days,
                listObject.price_for_six_days,
                listObject.price_for_seveen_days
            ],
            weeklyPrice: [
                listObject.weekly_price_for_one_day,
                listObject.weekly_price_for_two_days,
                listObject.weekly_price_for_three_days,
                listObject.weekly_price_for_four_days,
                listObject.weekly_price_for_five_days,
                listObject.weekly_price_for_six_days,
                listObject.weekly_price_for_seveen_days
            ]
        })
    };

    updatePrice = (event: React.ChangeEvent<HTMLInputElement>, priceIndex: number, occurance: string) => {
        const price = event.target.value.replace(/[^\d.]/g, '');
        if (occurance === "weekly") {
            this.setState(prevState => {
                const newState = [...prevState.weeklyPrice];
                newState[priceIndex] = price;
                return { weeklyPrice: newState };
            });
        } else {
            this.setState(prevState => {
                const newState = [...prevState.oneTimePrice];
                newState[priceIndex] = price;
                return { oneTimePrice: newState };
            });
        }
    };

    submitForm = async () => {
        const hotelId = await getStorageData('BoHotelId');
        const formData = new FormData();
        formData.append(`day_care_service[spaces]`, this.state.spaces);
        formData.append(`day_care_service[price_for_one_day]`, this.state.oneTimePrice[0]);
        formData.append(`day_care_service[price_for_two_days]`, this.state.oneTimePrice[1]);
        formData.append(`day_care_service[price_for_three_days]`, this.state.oneTimePrice[2]);
        formData.append(`day_care_service[price_for_four_days]`, this.state.oneTimePrice[3]);
        formData.append(`day_care_service[price_for_five_days]`, this.state.oneTimePrice[4]);
        formData.append(`day_care_service[price_for_six_days]`, this.state.oneTimePrice[5]);
        formData.append(`day_care_service[price_for_seveen_days]`, this.state.oneTimePrice[6]);
        formData.append(`hotel_id`, hotelId);
        formData.append(`day_care_service[weekly_price_for_one_day]`, this.state.weeklyPrice[0]);
        formData.append(`day_care_service[weekly_price_for_two_days]`, this.state.weeklyPrice[1]);
        formData.append(`day_care_service[weekly_price_for_three_days]`, this.state.weeklyPrice[2]);
        formData.append(`day_care_service[weekly_price_for_four_days]`, this.state.weeklyPrice[3]);
        formData.append(`day_care_service[weekly_price_for_five_days]`, this.state.weeklyPrice[4]);
        formData.append(`day_care_service[weekly_price_for_six_days]`, this.state.weeklyPrice[5]);
        formData.append(`day_care_service[weekly_price_for_seveen_days]`, this.state.weeklyPrice[6]);
        this.editDayCareServiceCallId = await this.apiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.updateApiMethodType,
            endPoint: `${configJSON.editDayCareEndpoint.replace("{hotelId}", this.state.dayCareId)}`,
            body: formData,
            type: "formData"
        })
    };

    editDayCareServiceResponnse = (responseJson: { day_care_service: { data: object } }) => {
        this.setState({ dayCareEditResp: responseJson, isSuccess: true }, () => {
            setTimeout(() => {
                this.setState({ dayCareEditResp: responseJson, isSuccess: false });
            }, 2000);
        });
    };

    handleDisable = (event: React.ChangeEvent<HTMLInputElement>, roomId: number) => {
        event.persist();
        if (roomId === this.state.selectedHotelIdEdit) {
            this.setState({ isEnable: !this.state.isEnable });
        }
        const { checked } = event.target;
        const roomStatus = checked ? "active" : "disable";

        this.setState((prevState) => ({
            chnagedRoomData: prevState.chnagedRoomData.map((room) => {
                if (room.room_id === roomId) {
                    return { ...room, status: roomStatus };
                }
                return room;
            })
        }));
    };
    enabledRoomSuccessCallBack = () => {
        this.getHotelRooms();
    };

    disabledRoomSuccessCallBack = () => {
        this.setState({ pageLoading: false });
    };
    deleteHotelroom = async () => {
        const { room_id } = this.state
        this.apiCallIdDeleteRoom = await this.apiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.deleteMethiodType,
            endPoint: `${configJSON.deleteRoomEndpoint.replace("[room_id]", room_id)}`
        })
    };

    openDeleteRoomModal = (room_id: number) => {
        this.setState({ deleteRoomDailogStatus: true, room_id: room_id })

    }

    closeDeleteModalDailog = () => {
        this.setState({ deleteRoomDailogStatus: false })

    }

    moveForwardImages = async (imageId: number,) => {
        const hotelId = await getStorageData('BoHotelId');
        this.apiCallIdMoveforward = await this.apiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.updateApiMethodType,
            endPoint: `bx_block_custom_form/move_forward?id=${hotelId}&image_id=${imageId}`
        })
    }

    updateSpace = (event: { target: { value: string } }) => {
        const numberOfSpaces = event.target.value;
        if (configJSON.numberRegex.test(numberOfSpaces)) {
            this.setState({
                spaces: numberOfSpaces
            });
        }
    };

    clearSelectedDates = () => {
        this.setState({
            selectedRange: [], dateRange: "",
            startDate: "", endDate: "",
            openDateStatus: false
        }, () => {
            this.getHotelReviews()
        })
    }



    editCheckIncheckOutDates = () => {
        this.setState({ checkInCheckOutStatus: true })
    }


    closeCheckIncheckOutDates = () => {
        this.setState({ checkInCheckOutStatus: false })
    }

    editTimingsCheck=(type:string)=>{
        if(this.state.checkInCheckOutStatus){
            this.selectCheckDropdown(type)
        }
    }


    selectCheckDropdown = (type: string) => {
        if (type === "check_in_start") {
            this.setState({ status_checked: { ...this.state.status_checked, checkInStartStatus: true } })
        } else if (type === "check_in_end") {
            this.setState({ status_checked: { ...this.state.status_checked, checkInEndStatus: true } })
        } else if (type === "check_out_start") {
            this.setState({ status_checked: { ...this.state.status_checked, checkOutStartStatus: true } })
        }else if (type === "check_out_end") {
            this.setState({ status_checked: { ...this.state.status_checked, checkOutEndStatus: true } })
        }

    }

    closeCheckedTimingsPopup = () => {
        this.setState({
            status_checked: {
                ...this.state.status_checked,
                checkInStartStatus: false,
                checkInEndStatus: false,
                checkOutEndStatus: false,
                checkOutStartStatus: false
            }
        })
    }

    select_check_in_check_out = (timeValue: string, type: string) => {
        if (type === "check_in_start") {
            this.setState({ checkInCheckOut: { ...this.state.checkInCheckOut, checkInStart: timeValue } }, () => {
                this.closeCheckedTimingsPopup()

            })
        } else if (type === "check_in_end") {
            this.setState({ checkInCheckOut: { ...this.state.checkInCheckOut, checkInEnd: timeValue } }, () => {
                this.closeCheckedTimingsPopup()

            })
        } else if (type === "check_out_start") {
            this.setState({ checkInCheckOut: { ...this.state.checkInCheckOut, checkOutStart: timeValue } }, () => {
                this.closeCheckedTimingsPopup()

            })
        }else if (type === "check_out_end") {
            this.setState({ checkInCheckOut: { ...this.state.checkInCheckOut, checkOutEnd: timeValue } }, () => {
                this.closeCheckedTimingsPopup()
            })
        }

    }

    select_time_mandatory=(value:boolean)=>{
        if(!value){
            this.setState({
                checkInCheckOut: {
                    ...this.state.checkInCheckOut,
                    checkInStart: "",
                    checkInEnd: "",
                    checkOutStart: "",
                    checkOutEnd: ""
                }
            })
        }else{
            this.setState({
                checkInCheckOut: {
                    ...this.state.checkInCheckOut,
                    checkInStart: "08:00",
                    checkInEnd: "11:30",
                    checkOutStart: "18:00",
                    checkOutEnd: "19:30",
                }
            })
        }
        this.setState({selectTiming:value})

    }

    updateCheck_in_check_out_timing = async () => {
        const hotelId = await getStorageData('BoHotelId');
        const formData = new FormData();
        const { checkInStart, checkInEnd, checkOutStart, checkOutEnd } = this.state.checkInCheckOut

        formData.append('hotel[check_in_start]', checkInStart);
        formData.append('hotel[check_in_end]', checkInEnd);
        formData.append('hotel[check_out_start]', checkOutStart);
        formData.append('hotel[check_out_end]', checkOutEnd);
        formData.append(`hotel[check_in_check_out_times_present]`, this.state.selectTiming.toString());
        formData.append(`hotel_id`, hotelId);
        this.apiCallIdUpdateHotel = await this.apiCall({
            method: configJSON.updateApiMethodType,
            endPoint: `${configJSON.updateHotelEndPoint}/${hotelId}`,
            body: formData,
            type: "formData"
        })
    }
    handleSuggestionClick = (list: any) => {
        const { lat, lon } = list;
        this.setState({
          coords: [parseFloat(lat), parseFloat(lon)],
          locationListPopup: false
        }, () => {
          this.getLocationPostCode(lat, lon)
          this.closeLocationPopup()
        }
        );
    
      }
    closeLocationPopup=()=>{
        this.setState({locationListPopup:false})
    }

    // Customizable Area End
}

import React from "react";

// Customizable Area Start
import { theme } from "../../../components/src/theme";
import Badge from '@material-ui/core/Badge';
import { withStyles, Typography, Grid, AppBar, Toolbar, Button, Avatar, createStyles, Theme } from '@material-ui/core'

const StyledBadge = withStyles(() => ({
  badge: {
    height: '12px', // Change this to desired height
    minWidth: '12px', // Change this to desired width
    fontSize: '12px', // Optionally change font size if needed
    borderRadius: '50%',
    padding: '4px', // Adjust padding to fit content
  },
}))(Badge);
import { petHotel } from "../../../components/src/Navbar";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

// Customizable Area End

import PetOwnerNavigationMenuController, {
  Props,
  configJSON,
} from "./PetOwnerNavigationMenuController.web";

export class PetOwnerNavigationMenu extends PetOwnerNavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { profilePicture, pinkDotActivity, is_loggedin,navigationToaster,navigationToasterMessage,toasterType } = this.state
    window.addEventListener('resize', this.navBarResize);
    return (
      <>
      <CustomizedSnackbars openToaster={navigationToaster} message={navigationToasterMessage} duration={3000} toasterType={toasterType} closeStatus={this.closeToasterNavigation}/>

        <AppBar position="fixed" className={this.props.activeLink === 1 ? classes.root : classes.rootshadow}>
          <Toolbar style={webStyle.toolBar as React.CSSProperties}>

              <>
                {this.props.navType === 'fullNavbar' ? (
                  <>
                    <div className={classes.routingLayout}>
                      {/* Render logo */}
                      <Typography component={'div'} onClick={() => this.petOwnerHandleNavigation(configJSON.navigationButtons.find_hotels)} data-test-id="pet_logo">
                        <img src={petHotel} alt="PET LOGO" style={{ marginRight: "30px",cursor:'pointer' }} />
                      </Typography>

                      {/* Map over an array of navigation items */}
                      {[
                        { label: 'Find Hotels', navButton: configJSON.navigationButtons.find_hotels, linkId: 1 },
                        { label: 'Doggy Daycare', navButton: configJSON.navigationButtons.doggy_daycare, linkId: 7 },
                        { label: 'About Us', navButton: configJSON.navigationButtons.about_us, linkId: 2 },
                        { label: 'My Reservations', navButton: configJSON.navigationButtons.my_reservations, linkId: 6, condition: is_loggedin },
                        {
                          label: 'Why Register',
                          navButton: configJSON.navigationButtons.why_register,
                          linkId: 3,
                          condition: !is_loggedin
                        },
                        {
                          label: configJSON.navigationButtons.inbox, navButton: configJSON.navigationButtons.inbox, linkId: 3, condition: is_loggedin,
                          showBadge:pinkDotActivity.new_chat
                        },
                        { label: 'Help', navButton: configJSON.navigationButtons.help, linkId: 4 },
                        { label: 'FAQs', navButton: configJSON.navigationButtons.faqs, linkId: 5 },
                      ].map((item, index) => (
                        item.condition !== false && (
                          <Typography
                            key={index}
                            component={'div'}
                            onClick={() => this.petOwnerHandleNavigation(item.navButton)}
                            data-test-id="nav_link"
                            style={this.props.activeLink === item.linkId ? webStyle.selectedNavLink as React.CSSProperties
                              : webStyle.findHotels as React.CSSProperties}
                          >
                            {item.showBadge ? (
                              <StyledBadge badgeContent=" " color="secondary">
                                {item.label}
                              </StyledBadge>
                            ) : (
                              item.label
                            )}
                          </Typography>
                        )
                      ))}
                    </div>
                    {is_loggedin ?
                      <>
                        {this.props.pageName != "profile" &&
                          <Typography component={'div'} onClick={() => this.petOwnerHandleNavigation(configJSON.navigationButtons.avatar)}
                          data-test-id="login_profile"
                          >
                            <Avatar style={webStyle.avatar} src={profilePicture} />
                          </Typography>
                        }
                      </>
                      :
                      <>
                        <Grid container justifyContent='center' alignItems='center' style={{ width: 'auto' }}>
                          <Typography component={'div'}>
                            <Button variant="outlined" style={webStyle.signInButton as React.CSSProperties}
                              onClick={() => this.navBarNavigation(configJSON.navigationButtons.pet_owner_signin)}
                              data-test-id="sign_in"
                            >{configJSON.navigationButtons.pet_owner_signin}</Button>
                          </Typography>
                          <Typography component={'div'}>
                            <Button variant="contained" style={webStyle.loginButtons as React.CSSProperties}
                              onClick={() => this.navBarNavigation(configJSON.navigationButtons.pet_owner_register)}
                              data-test-id="register"
                            >{configJSON.navigationButtons.pet_owner_register}</Button>
                          </Typography>
                          <Typography component={'div'}>
                            <Button variant="contained" style={webStyle.loginButtons as React.CSSProperties}
                              onClick={() => this.navBarNavigation(configJSON.navigationButtons.business_owner_signin)}
                              data-test-id="bussiness_login"
                            >{configJSON.navigationButtons.business_owner_signin}</Button>
                          </Typography>
                        </Grid>
                      </>

                    }
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Typography component={'div'}
                        onClick={() => this.navBarNavigation(configJSON.navigationButtons.find_hotels)}
                        data-test-id="logo_icon"
                      >
                        <img
                          src={petHotel}
                          alt="PET LOGO"
                          style={{ marginRight: "30px",cursor:'pointer' }}
                        />
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {this.props.authContent === 'Bo' ? (
                        <>
                          <Typography variant="subtitle2" className={classes.accountTypeText}>
                            Looking for Pet Hotels
                          </Typography>
                          <Typography
                            component={'div'}
                            onClick={() => this.navBarNavigation(configJSON.navigationButtons.pet_owner_signin)}
                            data-test-id="find_hotels"

                            style={{
                              color: "#0F172A",
                              fontWeight: 700,
                              fontFamily: "Inter",
                              borderBottom: "1px solid #0F172A",
                              textDecoration: "none",
                            }}
                          >
                            Find Hotels
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="subtitle2" className={classes.accountTypeText}>Do you own a hotel?</Typography>
                          <Typography component={'div'}
                            onClick={() => this.navBarNavigation(configJSON.navigationButtons.bussiness_owner_register)}
                            data-test-id="bussiness_account"

                            style={{
                              color: "#0F172A",
                              fontWeight: 700,
                              textDecoration: "none",
                              borderBottom: "1px solid #0F172A",
                            }}
                          >
                            Business Account
                          </Typography>
                        </>
                      )}
                    </div>{" "}
                  </>
                )}
              </>
          
          </Toolbar>
        </AppBar >

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const NavbarStyles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#ffffff',
      fontFamily: "inter",
      boxShadow: "none !important",

      padding: "10px 0px",
      [theme.breakpoints.down('xs')]: {
        padding: "0px !important"
      }
    }, rootshadow: {
      backgroundColor: '#ffffff',
      fontFamily: "inter",
      boxShadow: "0px 4px 8px 0px rgba(67, 134, 161, 0.03), 0px 8px 32px 0px rgba(67, 134, 161, 0.06)",

      padding: "10px 0px",
      [theme.breakpoints.down('xs')]: {
        padding: "0px !important"
      }
    },
    companyLogo: {
      width: " 262px",
      height: "88px",

      [theme.breakpoints.down('xs')]: {
        width: "114.31px",
        height: "39.11px",

      }
    },

    routingLayout: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 5,
    },
    mobileAvatar: {
      width: 44,
      height: 44
    },

    listProperty: {
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      textDecoration: "none",
      width: 'max-content',
      marginRight: 10

    },

    signInButton: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      border: `1px solid ${theme.palette.secondary.main}`,
      textTransform: "none",
      [theme.breakpoints.down('xs')]: {
        fontSize: "10.25px",
        height: "Fixed (28.19px)",
        borderRadius: "5.13px",
        gap: "5.13px"

      }
    },

    accountTypeText: {
      color: theme.palette.common.black,
      textAlign: "center",
      marginRight: "10px",
    },
    IconColor: {
      color: theme.palette.common.black
    },
    drawerPaper: {
      width: "230px",
      opacity: '0.87',
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      justifyContent: 'space-between',
      height: 'fit-content'
    },
    drawerCell: {
      padding: 40
    },
    iconCursor: {
      cursor: 'pointer'
    },
    registerBtn: {
      backgroundColor: "#EA0C78",
      color: "#FFFFFF",
      fontWeight: 700,
      textTransform: "none",
      [theme.breakpoints.down('xs')]: {
        fontSize: "10.25px",
        width: "Hug (63.5px)",
        height: "Fixed (28.19px)",
        padding: "6.41px, 10.25px, 6.41px, 10.25px",
        borderRadius: "5.13px",
        gap: "5.13px"

      }
    },
    statusModalContainerRequest: {
      position: "absolute",
      zIndex: 9,
      width: "100%",
      borderRadius: "0 0 30px 30px",
      backgroundColor: "#F0FAFE",
      top: 0,
      right: 0,
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
      margin: "15px 0 15px 0"
    }
  })


const NavBars = {
  btns: {
    fontSize: "14px",
    fontWeight: 700,
  },
  statusModalContainerRequest: {
    position: "absolute" as const,
    zIndex: 9,
    width: "100%",
    minHeight: "532px",
    borderRadius: "0 0 30px 30px",
    backgroundColor: "#F0FAFE",
    top: 0,
    right: 0,
    height: '100%'
  },
  statusModalRequest: {
    padding: "2rem"
  },
};

const webStyle = {
  loginButtons: {
    backgroundColor: "#EA0C78",
    color: "#FFFFFF", fontWeight: 700,
    marginLeft: "10px", textTransform: "none",
  },
  signInButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    border: `1px solid ${theme.palette.secondary.main}`,
    textTransform: "none",
    [theme.breakpoints.down('xs')]: {
      fontSize: "10.25px",
      height: "Fixed (28.19px)",
      borderRadius: "5.13px",
      gap: "5.13px"

    }
  },
  signInLayout: {
    width: 'auto !important'
  },
  selectedNavLink: {
    color: theme.palette.common.black,
    marginRight: 15,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: "underline",
    cursor: 'pointer'
  },
  findHotels: {
    color: theme.palette.common.black,
    textDecoration: "none",
    fontSize: 16,
    cursor: 'pointer',
    marginRight: 15,
    '&:focus': {
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: "underline",

    }
  },
  avatar: {
    height: 68,
    width: 68,
    backgroundColor: "#64C9F2",
    border: "1.5px solid #54A7C9",
    cursor:"pointer"
  },
  toolBar: {
    display: "flex",
    justifyContent: "space-between"
  },
}
export default withStyles(NavbarStyles)(PetOwnerNavigationMenu);

// export default (PetOwnerNavigationMenu);
// Customizable Area End
